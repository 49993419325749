<template>
  <v-container fluid>
    <v-card>
      <v-toolbar
        color="secondary"
        flat
        dense
      >
        <v-toolbar-title>{{setup.FormName}} Form Settings</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-spacer></v-spacer>
        <v-btn
          color="red"
          icon
          :to=" activeObject != 66 ?`/sales/doc/create/${activeObject}` :  `/inventory/transactions/create/${activeObject}`"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <!-- start of header fields row -->
        <v-row justify="center">
          <v-expansion-panels
            accordion
            focusable
            v-model="myPanel"
          >
            <v-expansion-panel>
              <v-expansion-panel-header>Header Fields</v-expansion-panel-header>
              <v-expansion-panel-content>
                <!-- search field -->
                <v-col cols="12">
                  <v-text-field
                    v-model="headerSearch"
                    label="Search"
                  ></v-text-field>
                </v-col>
                <!-- end of search field -->

                <!-- start of data table -->
                <v-data-table
                  dense
                  :loading="loading"
                  item-key="id"
                  loading-text="Loading... Please wait"
                  :headers="bodyHeaders"
                  :items="setup.HeaderFields"
                  :search="headerSearch"
                >
                  <!-- visible template -->
                  <template v-slot:item.Visible="props">
                    <v-checkbox
                      true-value="Y"
                      false-value="N"
                      v-model="props.item.Visible"
                    ></v-checkbox>
                  </template>
                  <!-- end of visible template -->

                  <!-- readonly template -->
                  <template v-slot:item.Readonly="props">
                    <v-checkbox
                      true-value="Y"
                      false-value="N"
                      v-model="props.item.Readonly"
                    ></v-checkbox>
                  </template>
                  <!-- end of readonly template -->

                  <!-- required template -->
                  <template v-slot:item.Required="props">
                    <v-checkbox
                      true-value="Y"
                      false-value="N"
                      v-model="props.item.Required"
                    ></v-checkbox>
                  </template>
                  <!-- end of required template -->
                </v-data-table>
                <!-- end of datatable -->
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
        <!-- end of header fields row -->

        <!-- start of table document_lines panel -->
        <v-row
          v-for="(tab, index) in setup.tabs"
          v-bind:key="index"
        >
          <v-expansion-panels
            accordion
            focusable
            v-model="tablePanel"
            v-if="tab.WithTable == 1"
          >
            <v-expansion-panel>
              <v-expansion-panel-header>Table Rows</v-expansion-panel-header>
              <v-expansion-panel-content>
                <!-- search field -->
                <v-col cols="12">
                  <v-text-field
                    v-model="rowSearch"
                    label="Search"
                  ></v-text-field>
                </v-col>
                <!-- end of search field -->
                <!-- start of data table -->
                <v-data-table
                  dense
                  :items-per-page="itemsPerPage"
                  :loading="loading"
                  loading-text="Loading... Please wait"
                  :headers="tableHeaders"
                  :items="tab.tableRows"
                  :search="rowSearch"
                >
                  <!-- visible template -->
                  <template v-slot:item.Visible="props">
                    <v-checkbox
                      true-value="Y"
                      false-value="N"
                      v-model="props.item.Visible"
                    ></v-checkbox>
                  </template>
                  <!-- end of visible template -->

                  <!-- modal visible template -->
                  <template v-slot:item.modalVisible="props">
                    <v-checkbox
                      true-value="Y"
                      false-value="N"
                      v-model="props.item.modalVisible"
                    ></v-checkbox>
                  </template>
                  <!-- end of modal visible template -->

                  <!-- readonly template -->
                  <template v-slot:item.readonly="props">
                    <v-checkbox
                      true-value="Y"
                      false-value="N"
                      v-model="props.item.readonly"
                    ></v-checkbox>
                  </template>
                  <!-- end of modal readonly template -->
                </v-data-table>
                <!-- end of datatable -->
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
        <!-- end of table document_lines panel -->

        <!-- start of footer fields -->
        <v-row justify="center">
          <v-expansion-panels
            accordion
            focusable
            v-model="myPanel"
          >
            <v-expansion-panel>
              <v-expansion-panel-header>Footer Fields</v-expansion-panel-header>
              <v-expansion-panel-content>
                <!-- search field -->
                <v-col cols="12">
                  <v-text-field
                    v-model="footerSearch"
                    label="Search"
                  ></v-text-field>
                </v-col>
                <!-- end of search field -->
                <!-- start of data table -->
                <v-data-table
                  :loading="loading"
                  item-key="id"
                  loading-text="Loading... Please wait"
                  :headers="bodyHeaders"
                  :items="setup.FooterFields"
                  :search="footerSearch"
                >
                  <!-- visible template -->
                  <template v-slot:item.Visible="props">
                    <v-checkbox
                      true-value="Y"
                      false-value="N"
                      v-model="props.item.Visible"
                    ></v-checkbox>
                  </template>
                  <!-- end of visible template -->

                  <!-- readonly template -->
                  <template v-slot:item.Readonly="props">
                    <v-checkbox
                      true-value="Y"
                      false-value="N"
                      v-model="props.item.Readonly"
                    ></v-checkbox>
                  </template>
                  <!-- end of readonly template -->

                  <!-- required template -->
                  <template v-slot:item.Required="props">
                    <v-checkbox
                      true-value="Y"
                      false-value="N"
                      v-model="props.item.Readonly"
                    ></v-checkbox>
                  </template>
                  <!-- end of required template -->
                </v-data-table>
                <!-- end of datatable -->
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
        <!-- end of footer fields -->

        <v-row>
          <v-col cols="12">
            <v-btn
              color="primary"
              @click="saveSettings"
              :loading="loader"
            >Save</v-btn>
          </v-col>
        </v-row>

        <snackbar ref="snackbar"></snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loader: false,
      headerSearch: null,
      rowSearch: null,
      footerSearch: null,
      myPanel: 0,
      tablePanel: 0,
      itemsPerPage: 100,
      record: {},
      setup: {},
      bodyHeaders: [
        { text: "Label", value: "Label" },
        { text: "Field Name", value: "FieldName" },
        { text: "Field Type", value: "FieldType" },
        { text: "Column Width(cols)", value: "ColumnWidth" },
        { text: "Visible", value: "Visible" },
        { text: "Readonly", value: "Readonly" },
        { text: "Required", value: "Required" },
      ],
      tableHeaders: [
        { text: "Table Header", value: "text" },
        { text: "Field Name", value: "value" },
        { text: "Field Type", value: "FieldType" },
        { text: "Width", value: "width" },
        { text: "Row Visible", value: "Visible" },
        { text: "Modal Visible", value: "modalVisible" },
        { text: "Readonly", value: "readonly" },
      ],
    };
  },
  watch: {
    "$route.params.objectId": {
      handler: "formSettings",
      immediate: true,
    },
  },
  methods: {
    formSettings(objectId) {
      if (objectId) {
        this.activeObject = objectId;
        const self = this;
        this.loading = true;
        this.$store
          .dispatch("get", `/form_settings/${objectId}`)
          .then((res) => {
            self.setup = res;
            self.loading = false;
          })
          .catch((err) => {
            console.log(err, "error");
          });
      }
    },
    saveSettings() {
      const data = this.setup;
      data.ObjType = 13;
      const url = "/form_settings";
      const self = this;
      self.loader = true;
      this.$store
        .dispatch("post", { url, data })
        .then(() => {
          self.loader = false;
          // self.$refs.snackbar.show("Item group created", "green");
          location.reload();
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
    save() {
      this.$refs.snackbar.show("Data saved", "green");
    },
    cancel() {
      this.$refs.snackbar.show("Cancelled", "green");
    },
    open() {},
    close() {
      console.log("Dialog closed");
    },
  },
  created() {
    this.formSettings();
  },
};
</script>

<style>
.small {
  max-width: 600px;
  margin: 150px auto;
}
</style>