<template>
    <v-container fluid>
      <!-- table -->
      <v-data-table
        v-model="selectedDocs"
        @item-selected="clickedDocs"
        item-key="id"
        :headers="visibleHeaders"
        :items="saleDocs"
      >
        <!-- dynamic datatable logic -->
        <template v-for="(dataRecord, index) in visibleHeaders" v-slot:[`item.${dataRecord.value}`]="props">
          <v-edit-dialog
            v-bind:key="index"
            :return-value.sync="props.item[dataRecord.value]"
            @save="save"
            @cancel="cancel"
            @open="open"
            @close="close"
          >
            <v-text-field v-model="props.item[dataRecord.value]" single-line></v-text-field>
            <template v-slot:input>
              <v-text-field v-model="props.item[dataRecord.value]"  label="Field Name" single-line></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
        <!-- end of datatable -->

        <!-- working code -->
         <!-- <template v-slot:item.ItemNo="props">
              <v-edit-dialog
                :return-value.sync="props.item.ItemNo"
                @save="save"
                @cancel="cancel"
                @open="open"
                @close="close"
              >
                <v-text-field v-model="props.item.ItemNo" single-line></v-text-field>
                <template v-slot:input>
                  <v-text-field v-model="props.item.ItemNo" label="Total Payment" single-line></v-text-field>
                </template>
              </v-edit-dialog>
            </template> -->
        <!-- end of the working code  -->
      </v-data-table>
      <!-- end -->
        <div>
          <v-btn color="primary" @click="logData">save</v-btn>
        </div>
       <snackbar ref="snackbar"></snackbar>
    </v-container>
</template>

<script>
export default {
  computed: {
    visibleHeaders() {
      const headers = this.tableRows.filter(record => {
        return record.Visible === "Y";
      });
      return headers;
    },
  },
  data() {
    return {
      setup: {},
      record: {},
      saleDocs: [
        { ItemNo: 1, ItemDesc: "Tea", UnitPrice: 500 },
        { ItemNo: 2, ItemDesc: "Coffee", UnitPrice: 700 }
      ],
      selectedDocs: [],
      tableRows: [
        { text: "Item No", value: "ItemNo", Visible: "Y",},
        { text: "Item Description", value: "ItemDesc", Visible: "Y" },
        { text: "Unit Price", value: "UnitPrice", Visible: "Y" }
      ]
    };
  },
  methods: {
    getData(data) {
      return this.$data[data];
    },
    // save() {
    //   console.log(this.record, "record");
    // },
    clickedDocs($event) {
      console.log($event, "event");
    },
    formSettings() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/form_settings/15`)
        .then(res => {
          console.log(res, 'r');
          self.setup = res;
          self.loading = false;
        })
        .catch(err => {
          console.log(err, "error");
        });
    },
    save() {
      this.$refs.snackbar.show("Data saved", "green");
    },
    cancel() {
      this.$refs.snackbar.show("Cancelled", "green");
    },
    open() {},
    close() {
      console.log("Dialog closed");
    },
    logData() {
      console.log(this.saleDocs, 'sale docs');
    }
  },
  created() {
    this.formSettings();
  }
};
</script>

<style scoped>
</style>