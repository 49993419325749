const decode = require("jwt-decode");

export default {
  user() {
    const token = localStorage.getItem("token") || null;
    let user = {};

    if (token) {
      user = decode(token);
      return user;
    }
    return null;
  },

  getWarehouseData(state) {
    return state.warehouses;
  },
  getOutputTaxGroups(state) {
    return state.outputTaxGroups;
  },
  getInputTaxGroups(state) {
    return state.inputTaxGroups;
  },
  getPriceList(state) {
    return state.priceList;
  },
  getCreditCards(state) {
    return state.creditCards;
  },
  getActiveGlAccounts(state) {
    return state.activeGlAccounts;
  },
  getDistributionRules(state) {
    return state.distributionRules;
  },
  getBranchDistributionRules(state) {
    return state.branchDistributionRules;
  },
  getDocumentModel(state) {
    return state.documentModel;
  },
  getDefaultSettings(state) {
    return state.documentModel;
  },
  getUomGroups(state) {
    return state.uomGroups;
  },
  getDocumentRights(state) {
    return state.docRights;
  },
  getLocalSettings(state) {
    return state.localSettings;
  },
};
