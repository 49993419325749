<template>
    <v-chart class="chart" :option="chartOption" autoresize />
  </template>
  
  <script>
    import {  defineComponent, computed, watch} from 'vue';
    import VChart,{ THEME_KEY } from 'vue-echarts';
    import * as echarts from 'echarts/core';
    import { 
      TitleComponent,
      TooltipComponent,
      LegendComponent,
      GridComponent,
     } from 'echarts/components';
    import { BarChart } from 'echarts/charts';
    import { CanvasRenderer } from 'echarts/renderers';

    echarts.use([
      CanvasRenderer,
      BarChart,
      GridComponent,
      TitleComponent,
      TooltipComponent,
      LegendComponent,
    ]);
  
  export default defineComponent({
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    components:{
        VChart,        
    },
    provide: {
        [THEME_KEY]: 'light',
    },
    setup(props) {
      const chartOption = computed(() => {
                
        return {
            title:{
                text: 'Invoices Summary',
                subtext: 'Last Month  ',
                left: 'center',
                top: 'top',
                textStyle: {
                    fontSize: 16,
                    fontWeight: 'bold',
                },
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                type: 'shadow'
                }
            },
            xAxis: {
                type: 'category',
                data: props.data.labels,
                axisTick: {
                    alignWithLabel: true
                },
                axisLabel: {
                // Enable automatic label rotation
                rotate: 45, // Adjust the rotation angle (degrees)
                overflow: 'ellipsis', // Trim long labels with ellipsis (...)
                }
            },
            yAxis: {
                type: 'value',
            },
            series: props.data.datasets.map(dataset => ({
                type: 'bar',
                name: dataset.label,
                data: dataset.data,
                barWidth: '60%',
                itemStyle: {
                color: dataset.backgroundColor,
                },
            })),
        };
      });
  
      
      watch(props.data, (newData) => {
            // Force update the chart when data changes
            chartOption.value = {
                ...chartOption.value,
                xAxis: {
                    ...chartOption.value.xAxis,
                    data: newData.labels,
                },
                series: newData.datasets.map(dataset => ({
                    ...dataset,
                    data: dataset.data,
                })),
            };
        });
  
      return { chartOption };
    },

    mounted() {
      // Force re-render on mount
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    }
  });
  </script>
  