<template>
  <section>
    <!-- start of sidebar -->
    <v-navigation-drawer
      v-bind:width="300"
      v-model="drawer"
      :mini-variant.sync="mini"
      parmanent
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
    >
      <!-- start of list -->
      <v-list>
        <template v-for="(item, i) in items">
          <v-list-group
            v-show="item.Visible == 'Y'"
            :model="falseValue"
            v-if="item.grand_children.length > 0"
            :key="i"
            :prepend-icon="item.icon"
          >
            <template v-slot:activator>
              <v-list-item-title>{{item.Label}}</v-list-item-title>
            </template>

            <template v-for="child in item.grand_children">
              <!-- with sub children -->
              <v-list-group
                :model="falseValue"
                v-show="child.Visible == 'Y'"
                v-if="child.grand_children.length > 0"
                :key="child.Label"
                no-action
                sub-group
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>{{child.Label}}</v-list-item-title>
                  </v-list-item-content>
                </template>

                <!-- sub children template -->
                <template v-for="subChild in child.grand_children">
                  <v-list-group
                    :model="falseValue"
                    v-show="subChild.Visible == 'Y'"
                    v-if="subChild.grand_children.length > 0"
                    :key="subChild.Label"
                    no-action
                    sub-group
                  >
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item-title>{{subChild.Label}}</v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <v-list-item
                      v-show="grandChild.Visible == 'Y'"
                      v-for="(grandChild, i) in subChild.grand_children"
                      :key="i"
                      :to="grandChild.link"
                    >
                      <v-list-item-icon
                        style="margin-left: -30px"
                        :to="item.link"
                      >
                        <v-icon color="accent">mdi-format-list-bulleted</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title
                        style="margin-left: -15px"
                        v-text="grandChild.Label"
                      ></v-list-item-title>
                    </v-list-item>
                  </v-list-group>

                  <!-- no grand child -->
                  <v-list-item
                    v-else
                    :key="subChild.Label"
                    :to="subChild.link"
                    v-show="subChild.Visible == 'Y'"
                  >
                    <v-list-item-icon style="margin-left: -45px">
                      <v-icon color="red">mdi-format-line-weight</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title style="margin-left: -15px">{{subChild.Label}}</v-list-item-title>
                  </v-list-item>
                  <!-- end of no grand children -->
                </template>
                <!-- end of sub children template -->
              </v-list-group>
              <!-- end of with children -->

              <!-- no sub children -->
              <v-list-item
                v-else
                :key="child.Label"
                @click="navigate(child.link)"
                v-show="child.Visible == 'Y'"
              >
                <v-list-item-icon style="margin-left: 10px">
                  <v-icon color="red">mdi-format-line-weight</v-icon>
                </v-list-item-icon>

                <v-list-item-title style="margin-left: -20px">{{child.Label}}</v-list-item-title>
              </v-list-item>
              <!-- end of no sub children -->
            </template>
          </v-list-group>

          <v-list-item
            v-else
            :key="i"
            @click="navigate(item.link)"
            v-show="item.Visible == 'Y'"
          >
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{item.Label}}</v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
      <!-- end of list -->
    </v-navigation-drawer>
    <!-- end of sidebar -->

    <!-- start of appbar -->
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="primary"
      dark
    >
      <v-app-bar-nav-icon @click.stop="mini = !mini" />
      <v-toolbar-title class="ml-0 pl-4">
        <span class="hidden-sm-and-down">LEYSCO100 - {{ this.CurrentlyLoginUser.oadm.CompnyName}} </span>
      </v-toolbar-title>
      <v-spacer />

      <span>
        v0.6.0
      </span>
      <v-spacer />
      <span>
        {{ this.CurrentlyLoginUser.name}}
      </span>
      <v-btn
        icon
        to="/menu/form/settings"
      >
        <v-icon>mdi-apps</v-icon>
      </v-btn>

      <v-btn
        text
        color="white"
        @click="logout"
      >Logout</v-btn>
    </v-app-bar>
    <!-- end of appbar -->
  </section>
</template>
<script>

export default {
  data: () => ({
    loader: false,
    mini: false,
    drawer: true,
    trueValue: true,
    falseValue: false,
    parmanent: true,
    fixed: true,
  }),
  computed: {
    CurrentlyLoginUser() {
      return this.$store.state.user;
    },
    items() {
      return this.$store.state.menuData;
    },
    appVersion() {
      return this.$store.getters.appVersion;
    }

  },
  methods: {
    logout() {
      const self = this;
      self.loading = true;
      self.$store.commit("RESET_STATE");
      self.$store.commit("salesEmployee/clearData");
      self.$store.commit("documentFormSetting/clearData");
      self.$store.commit("ohem/clearData");
      self.$store.commit("currency/clearData");
      self.$store.commit("globalWarehouses/clearData");
      localStorage.removeItem("token");
      localStorage.removeItem("stoken");
      self.$store
        .dispatch("get", `/logout`)
        .then(() => {})
        .catch((err) => {
          console.log(err);
          self.loading = false;
        });
      this.$router.push("/login");
    },
    navigate(link) {
      this.$router.push(link);
    },
    getMenu() {
      const self = this;
      if (!self.items){
        self.loading = true;
        this.$store
            .dispatch("get", `/form_settings_menu`)
            .then((res) => {
              this.$store.commit("setMenuData", res.AllTreeview);
              self.loading = false;
            })
            .catch((err) => {
              console.log(err);
              self.loading = false;
            });
      }
    },
  },
  created() {
    this.getMenu();
  },
};
</script>

<style  scoped>
.my-nav {
  font-size: 10px;
}
</style>

