const IndexPage = () => import("./index");
const SalesPersons = () => import("./_components/salesPersons");
const Items = () => import("./_components/items");
const Customers = () => import("./_components/customers");

export default [
  {
    path: "/sales_reports",
    component: IndexPage,
    children: [
      {
        path: "",
        component: SalesPersons,
      },
      {
        path: "/sales_reports/items",
        component: Items,
      },
      {
        path: "/sales_reports/customers",
        component: Customers,
      },
    ],
  },
];
