<template>
  <v-container fluid>
    <v-row>
      <v-toolbar color="light">
        <v-toolbar-title style="text-transform: uppercase;"> {{ user.account }} Dashboard</v-toolbar-title>
      </v-toolbar>
    </v-row>

    <div class="col ma-6 pa-5 " v-if="loading">
      <h4 class="mb-5 font-bold"> Loading data, please wait .... </h4>
      <v-progress-linear color="primary" indeterminate="disable-shrink"></v-progress-linear>
    </div>

    <div class="dashboard" v-else>

      <div class="row mx-2 mt-1 mb-4 ">

        <div class="dash-card" v-for="stat in dashboardStats" v-bind:key="stat.title">
          <v-icon class="dash-icon-card" color="white">{{ stat.icon }}</v-icon>
          <div class="col">
            <div style="color: gray;">{{ stat.title }}</div>
            <div class="text-h5">{{ stat.value }}</div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="row col-12">
          <div class="col-6" v-if="chartsLoaded">
            <Chart :key="chart1DataKey" :data="chart1Data"/>
          </div>
          <div class="col-6" v-if="chartsLoaded">
            <Chart2 :key="chart2DataKey" :data="chart2Data"/>
          </div>          
        </div>
        
        <div class="row col-12">
          <div class="col-6" v-if="chartsLoaded">
            <Chart3 :key="chart3DataKey" :data="chart3Data"/>
          </div>
        </div>
        
      </div>

      <div class="dash-summary-card">
        <div class="text-h5">Sales Summary Data </div>
        <v-data-table :headers="salesSummaryHeaders" :items="salesSummary">
        </v-data-table>
      </div>

      <v-card class="mx-4 my-2 rounded-lg pa-2" style="width: 100%;">
        <bar-chart :chartData="chartData" :options="options"></bar-chart>
      </v-card>

    </div>

  </v-container>

</template>



<script>
// import LineChart from "./LineChart.js";
//import BarChart from "./BarChart.js";
import Chart from "./Chart";
import Chart2 from "./Chart2";
import Chart3 from "./Chart3";

export default {
  components: {
    //BarChart,
    Chart,
    Chart2,
    Chart3,
  },

  data() {
    return {
      loading: false,
      record: {},
      user: {},
      callsData: [],
      dashboardStats: [],
      dashboardData: {},
      salesSummary: [],
      chartData: {},
      chart1Data: {},
      chart2Data: {},
      chart3Data: {},
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
      salesSummaryHeaders: [
        { text: "Document", value: "documentName" },
        { text: "Today's Count", value: "totalToday" },
        { text: "Today's Total", value: "totalValueToday" },
        { text: "Weekly Total", value: "TotalWeekly" },
        { text: "Monthly Total", value: "TotalMonthly" },

      ],
      chart1DataKey: 0,
      chart2DataKey: 0,
      chart3DataKey: 0,
      chartsLoaded: false,
    };
  },

  mounted() {
    this.loadDashboardData()
  },

  computed: {},

  methods: {
    loadDashboardData() {
      this.user = JSON.parse(localStorage.getItem("stock")).user;
      const url = "/dashboard";
      this.loading = true;
      this.$store.dispatch("get", url).then((response) => {
        let data = response.ResponseData;
        this.salesSummary = data.SummaryData;
        this.dashboardStats = [
          { title: "Todays Calls", value: data.CallsToday, icon: "mdi-phone" },
          { title: "Total Outlets", value: data.TotalOutlets, icon: "mdi-domain" },
          { title: "Daily Target", value: data.SalesTargetDaily, icon: "mdi-clock" },
          { title: "Weekly Target", value: data.SalesWeekPerc, icon: "mdi-calendar-week" },
          { title: "Monthly Target", value: data.TotalMonthlySales, icon: "mdi-calendar-month" },
        ];
        const callsWeekSummary = data.CurrentWeekCallsSummary;
        const labels = [];
        const graphData = [];
        callsWeekSummary.forEach((item) => {
          labels.push(item.CallDate);
          graphData.push(item["TotalCalls "]);
        });
        this.chartData = {
          labels: labels,
          datasets: [
            {
              label: "Current Call week Summary",
              backgroundColor: "#536DFE",
              data: graphData,
            },
          ],
        };
        this.loadChartData();
        this.loading = false;
      });

      //charts summary data for invoices and credit notes
      // try{ 
      //   const chartUrl = "/getTarget";
      //   this.$store.dispatch("get", chartUrl).then((response) => {
      //     const data = response.ResponseData;
      //     console.log(data);
          
      //     //prepare data for chart1
      //     const lineTotalData = data.map(item => item.LineTotal);
      //     const GTotalData = data.map(item => item.GTotal);
      //     //console.log(creditNoteLineTotalData);
      //     //console.log(data.map(item => item.SlpName));
      //     this.chart1Data = {
      //       labels: data.map(item => item.SlpName),
      //       datasets: [
      //         {
      //           label: 'Line Total',
      //           backgroundColor: '#42A5F5',
      //           data: lineTotalData,
      //         },
      //         {
      //           label: 'GTotal',
      //           backgroundColor: '#66BB6A',
      //           data: GTotalData,
      //         },
      //       ],
      //     };

          
      //     const creditNoteLineTotalData = data.map(item => item.CreditNoteLineTotal);
      //     const creditNoteGTotalData = data.map(item => item.CreditNoteGTotal);
      //     this.chart2Data = {
      //       labels: data.map(item => item.SlpName),
      //       datasets: [
      //         {
      //           label: 'Credit Line Total',
      //           backgroundColor: '#42A5F5',
      //           data: creditNoteLineTotalData,
      //         },
      //         {
      //           label: 'Credit GTotal',
      //           backgroundColor: '#66BB6A',
      //           data: creditNoteGTotalData,
      //         },
      //       ],
      //     };

      //     const invQuantityData = data.map(item => item.Quantity);
      //     const creditNoteQuantityData = data.map(item => item.CreditNoteQuantity);
      //     this.chart3Data = {
      //       labels: data.map(item => item.SlpName),
      //       datasets: [
      //         {
      //           label: 'Invoices Quantity',
      //           backgroundColor: '#42A5F5',
      //           data: invQuantityData,
      //         },
      //         {
      //           label: 'Credit Notes Quantity',
      //           backgroundColor: '#66BB6A',
      //           data: creditNoteQuantityData,
      //         },
      //       ],
      //     };
      //     this.chart1DataKey += 1;
      //     this.chart2DataKey += 1;
      //     this.chart3DataKey += 1;
      //   });

      // } catch (error) {
      //   console.error("Error loading dashboard data:", error);
      // } finally {
      //   this.loading = false;
      // }
    },
    loadChartData() {
      const chartUrl = "/getTarget";
      this.$store.dispatch("get", chartUrl).then((response) => {
        const data = response.ResponseData;
        // Prepare chart data
        this.chart1Data = {
          labels: data.map(item => item.SlpName),
          datasets: [
            {
              label: 'Line Total',
              backgroundColor: '#42A5F5',
              data: data.map(item => item.LineTotal),
            },
            {
              label: 'GTotal',
              backgroundColor: '#66BB6A',
              data: data.map(item => item.GTotal),
            },
          ],
        };

        this.chart2Data = {
          labels: data.map(item => item.SlpName),
          datasets: [
            {
              label: 'Credit Line Total',
              backgroundColor: '#42A5F5',
              data: data.map(item => item.CreditNoteLineTotal),
            },
            {
              label: 'Credit GTotal',
              backgroundColor: '#66BB6A',
              data: data.map(item => item.CreditNoteGTotal),
            },
          ],
        };

        this.chart3Data = {
          labels: data.map(item => item.SlpName),
          datasets: [
            {
              label: 'Invoices Quantity',
              backgroundColor: '#42A5F5',
              data: data.map(item => item.Quantity),
            },
            {
              label: 'Credit Notes Quantity',
              backgroundColor: '#66BB6A',
              data: data.map(item => item.CreditNoteQuantity),
            },
          ],
        };

        this.chart1DataKey += 1;
        this.chart2DataKey += 1;
        this.chart3DataKey += 1;
        this.chartsLoaded = true; // Set to true once charts are loaded
      }).catch(error => {
        console.error("Error loading chart data:", error);
      });
    }

  },

  created() {
    // this.getInitialData();
    // this.getDccuments();
  },

};
</script>

<style>
.chart {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px; /* Adjust the height as needed */
}
.dashboard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  width: 100%;
}

.dash-card {
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  background-color: white;
  width:auto;
  min-width: 300px;
  max-width: 500px;
  height: 110px;
  display: flex;
  flex: row;
  margin-right: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.dash-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  height: 112px;
  z-index: 2;
  font-size: larger;
  background-color: rgb(246, 242, 242);
}

.dash-icon-card {
  padding: 20px;
  text-align: center;
  margin: 10px;
  background-color: #536DFE;
  color: darkblue;
  border-radius: 20px;
  height: 60px;
}

.dash-summary-card {
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  margin: 20px;
  width: 100%;

}

@media (max-width: 1300px) {
  .dash-card{
    width: 40%;
  }
}

@media (max-width: 750px) {
  .dash-card {
    width: 100%;
    max-width: 100%;
    margin-right: 10px;
  }
}
</style>