const BpMasterIndex = () => import("./item-master-data/index");
const NewBpMasterData = () => import("./item-master-data/_pages/create");
const BpDetails = () => import("./item-master-data/_pages/view");
//dealers
const DealersIndex = () => import("./dealers/index");
const NewDealerData = () => import("./dealers/_pages/create");
const DealerDetails = () => import("./dealers/_pages/view");

export default [
  //Business partners
  {
    path: "/business-partners/item-master-data",
    component: BpMasterIndex,
  },
  {
    path: "/new-bp-master-data",
    component: NewBpMasterData,
  },
  {
    path: "/bp-master-data/:id",
    component: BpDetails,
  },
  //dealers
  {
    path: "/business-partners/dealers",
    component: DealersIndex,
  },
  {
    path: "/dealer/create",
    component: NewDealerData,
  },
  {
    path: "/dealer/view/:id",
    component: DealerDetails,
  },
];
