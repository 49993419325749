const Index = () => import('./index');
const create = () => import('./_pages/create')
const surveyDetails = () => import('./_pages/survey-details')
const Edit = () => import('./_pages/edit')
const rules = () => import('./_components/rules/rules')
const schedules = () => import('./_components/schedules/schedules')
const createRule = () => import('./_pages/rules/create')
const createSchedule = () => import('./_pages/schedules/create')
const response = () => import('./_pages/rules/response')
const responseCard = () => import('./_components/rules/responseCard');
const editResponse = () => import('./_pages/rules/edit-response');
export default [
    {
        path: '/survey',
        component: Index
    },
    {
        path: '/new-survey',
        component: create
    },
    {
        path: '/survey/details/:id',
        component: surveyDetails,
        children: [
            {
                path: '',
                component: Edit
            },
            {
                path: 'rules',
                name: 'rules',
                component: rules,
                children: [
                    {
                        path: 'new-rule',
                        name: 'new-rule',
                        component: createRule
                    },


                ]
            },
            {
                path: 'schedules',
                name: 'schedules',
                component: schedules,
                children: [
                    {
                        path: 'new-schedule',
                        name: 'new-schedule',
                        component: createSchedule
                    },

                ]
            },
        ]
    },
    {
        path: '/responses/:rule_id',
        component: response,
        children: [
            {
                path: '',
                name: 'responses',
                component: responseCard
            },
        ]
    },
    {
        path: '/edit-response/:response_id',
        component: editResponse
    }

];