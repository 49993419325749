<template>
  <v-app id="inspire">
    <!-- navigation -->
    <navigation></navigation>
    <!-- end of navigation -->

    <v-content>
        <slot></slot>
    </v-content>
  </v-app>
</template>

<script>
import Navigation from "./_components/admin-nav.vue";

export default {
  components: {
    Navigation
  },
  props: {
    source: String
  },
  data: () => ({
    dialog: false
  })
};
</script>