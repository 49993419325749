const ScanLogIndex = () => import("./scan-logs/index.vue");
const ScalLogView = () => import("./scan-logs/_pages/view.vue");
const GPMDocumentsIndex = () => import("./documents/index.vue");
const ReportIndex = () => import("./reports/index.vue");

const UserIndex = () => import("./users/index.vue");
const UserCreate = () => import("./users/_pages/create.vue");
const UserView = () => import("./users/_pages/view.vue");

const GateIndex = () => import("./gates/index.vue");
const GateCreate = () => import("./gates/_pages/create.vue");
const GateView = () => import("./gates/_pages/view.vue");

//settings
const SettingIndex = () => import("./settings/index.vue");
const SettingNotification = () => import("./settings/_pages/notification");
const SettingExtBucket = () => import("./settings/_pages/ext_bucket");
const SettingPasswordResets = () => import("./settings/_pages/password_resets");

//form fields
const FormFieldsIndex = () => import("./form-fields/index.vue");
const FormFieldsCreate = () => import("./form-fields/_pages/create.vue");
const FormFieldsView = () => import("./form-fields/_pages/view.vue");
const MobileMenuIndex = () => import("./settings/_mobile-menu/index.vue");
const FieldsTemplates = () => import("./form-fields/templates/index.vue");
const FieldsTemplatesShow = () => import("./form-fields/templates/_pages/view");
const FieldsTemplatesCreate = () =>
  import("./form-fields/templates/_pages/create.vue");
//reports
const ScanLogs = () => import("./reports/_pages/scan_logs.vue");
const DublicateScans = () => import("./reports/_pages/dublicate_scans.vue");
const DoesNotExistDocs = () => import("./reports/_pages/unexisting_docs.vue");
const DocumentReport = () => import("./reports/_pages/document_report.vue");
const BCPReport = () => import("./reports/_pages/bcp_reports");

//backup mode
const BackupMode = () => import("./backup-mode/index");
const BackupModeView = () => import("./backup-mode/_pages/view");
const BackupModeCreate = () => import("./backup-mode/_pages/create");
const AutoBackupModeCreate = () => import("./backup-mode/autoMode/index");

//roles
const RolesIndex = () => import("./roles/index.vue");

export default [
  {
    path: "/gpm-documents",
    component: GPMDocumentsIndex,
  },
  {
    path: "/gpm-scan-logs",
    component: ScanLogIndex,
  },
  {
    path: "/gpm-scan-logs/:id",
    component: ScalLogView,
  },

  {
    path: "/gpm/settings",
    component: SettingIndex,
    children: [
      {
        path: "",
        component: SettingNotification,
      },
      {
        path: "/gpm/settings/password_resets",
        component: SettingPasswordResets,
      },
      {
        path: "/gpm/settings/ext_bucket",
        component: SettingExtBucket,
      },
    ],
  },
  {
    path: "/gpm/reports",
    component: ReportIndex,
  },
  {
    path: "/gpm/gates",
    component: GateIndex,
  },
  {
    path: "/gpm/roles",
    component: RolesIndex,
  },
  {
    path: "/gpm/gates/create",
    component: GateCreate,
  },
  {
    path: "/gpm/gates/:id",
    component: GateView,
  },
  {
    path: "/gpm/users",
    component: UserIndex,
  },
  {
    path: "/gpm/users/create",
    component: UserCreate,
  },
  {
    path: "/gpm/users/:id",
    component: UserView,
  },
  {
    path: "/gpm/form-fields",
    component: FormFieldsIndex,
  },
  {
    path: "/gpm/form-fields/create",
    component: FormFieldsCreate,
  },
  {
    path: "/gpm/form-fields/:id",
    component: FormFieldsView,
  },
  {
    path: "gpm/mobile-menu",
    component: MobileMenuIndex,
  },
  {
    path: "/gpm/reports/scan_logs_reports",
    component: ScanLogs,
  },
  {
    path: "/gpm/reports/dublicate_logs_reports",
    component: DublicateScans,
  },
  {
    path: "/gpm/reports/notexist_docs_reports",
    component: DoesNotExistDocs,
  },
  {
    path: "/gpm/reports/document_reports",
    component: DocumentReport,
  },
  {
    path: "/gpm/reports/bcp_reports",
    component: BCPReport,
  },

  {
    path: "/gpm/form_field_template",
    component: FieldsTemplates,
  },
  {
    path: "/gpm/form_field_template/:id",
    component: FieldsTemplatesShow,
  },
  {
    path: "/gpm/field_template/create",
    component: FieldsTemplatesCreate,
  },
  {
    path: "/gpm/backup_mode",
    component: BackupMode,
  },

  {
    path: "/gpm/backup_mode/settings",
    component: AutoBackupModeCreate,
  },
  {
    path: "/gpm/backup_mode/create",
    component: BackupModeCreate,
  },
  {
    path: "/gpm/backup_mode/:id",
    component: BackupModeView,
  },
];
