<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="7" md="7">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>LEYSCO100</v-toolbar-title>
            <v-spacer />
            <v-icon>mdi-bank</v-icon>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6" style="align-text: center">
                <v-img src="@/assets/leyscologo.png"></v-img>
              </v-col>

              <v-col cols="12" md="6">
                Reset Your Password
                <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                  <v-text-field
                    label="Email"
                    v-model="user.email"
                    :rules="inputRules"
                    prepend-icon="mdi-account-outline"
                    type="text"
                  />

                  <v-text-field
                    id="password"
                    label="Password"
                    name="password"
                    v-model="user.password"
                    :rules="inputRules"
                    autocomplete="on"
                    prepend-icon="mdi-lock"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                  />

                  <v-text-field
                    id="password_confirmation"
                    label="Confirm Password"
                    name="password_confirmation"
                    v-model="user.password_confirmation"
                    :rules="inputRules"
                    autocomplete="on"
                    prepend-icon="mdi-lock"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                  />
                </v-form>
                <v-row no-gutters>
                  <v-btn
                    color="primary"
                    class="right"
                    @click="login"
                    :loading="loader"
                    >Reset Password</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions></v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- start of snackbar -->
    <snackbar ref="snackbar"></snackbar>
    <!-- end of snackbar -->
  </v-container>
</template>
  
  
  <script>
import AuthLayout from "@/layout/auth.vue";
export default {
  layout: AuthLayout,
  data: () => ({
    lazy: false,
    valid: false,
    loader: false,
    user: {},
    showPassword: false,
    inputRules: [(v) => !!v || "Required"],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
  }),
  watch: {
    params: {
      handler: "setEmail",
      immediate: true,
    },
  },
  methods: {
    setEmail() {
      this.user.email = this.$route.params?.email;
    },
    setUserData(user) {
      this.$store.commit("setUser", user);
    },
    login() {
      this.$store.commit("loader", false);

      if (this.$refs.form.validate()) {
        const url = "/reset/password";
        this.user.token = this.$route.params.token?.toString() || "default";
        this.user.email = this.$route.params?.email;
        const data = this.user;
        console.log(data);
        this.loader = true;
        const self = this;
        this.$store
          .dispatch("post", { url, data })
          .then((res) => {
            console.log(res);
            this.$refs.snackbar.show(res.message, "green");
            self.loader = false;
            this.$router.push("/login");
          })
          .catch((err) => {
            console.log(err);
            self.loader = false;
            this.$refs.snackbar.show(err.message, "red");
          });
      }
    },
  },
};
</script>
  