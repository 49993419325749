<template>
  <v-text-field :label="setup.label" :readonly="setup.readonly" :type="setup.type"></v-text-field>
</template>
   
<script>
export default {
  props: {
    setup: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  }
};
</script>

<style scoped>
</style>