// general
const UsersIndex = () => import("./general/users/index.vue");
const CreateUserPage = () => import("./general/users/_pages/create.vue");
const UserDetailsPage = () => import("./general/users/_pages/view.vue");

const UserGroupsIndex = () => import("./general/user-groups/index.vue");
const SalesEmployeesIndex = () => import("./general/sales-employees/index.vue");
const NewSalesEmployee = () =>
  import("./general/sales-employees/_pages/create.vue");
const SalesEmployeesEdit = () =>
  import("./general/sales-employees/_pages/view.vue");

const EmployeesIndex = () => import("./general/employees/index.vue");
const NewEmployee = () => import("./general/employees/_pages/create.vue");
const ViewEmployee = () => import("./general/employees/_pages/view.vue");

const EmpWarehouses = () =>
  import("./general/employees/_components/emp-warehouse.vue");
const EmpBusinessPartners = () =>
  import("./general/employees/_components/emp-business-partners.vue");
const EmpPayments = () =>
  import("./general/employees/_components/emp-payments.vue");
const EmpBranches = () =>
  import("./general/employees/_components/emp-branches.vue");
const ItemsAssignment = () =>
  import("./general/employees/_components/emp-items.vue");

const EmployeeAssignment = () =>
  import("./general/employees/_pages/assignments.vue");

const DriversIndex = () => import("./general/drivers/index.vue");
const NewDriver = () => import("./general/drivers/_pages/create.vue");
const ViewDriver = () => import("./general/drivers/_pages/view.vue");

const TerritoriesIndex = () => import("./general/territories/index.vue");
const NewTerritory = () => import("./general/territories/_pages/create.vue");
const SubTerritory = () =>
  import("./general/territories/_pages/create-child.vue");
const UserDefaultsIndex = () => import("./general/user-defaults/index.vue");
const CreateUserDefaults = () =>
  import("./general/user-defaults/_pages/create.vue");
const ViewUserDefaults = () =>
  import("./general/user-defaults/_pages/view.vue");

const DepartmentIndex = () => import("./general/departments/index.vue");
const CreateDepatment = () => import("./general/departments/_pages/create.vue");
const ViewDepartment = () => import("./general/departments/_pages/view.vue");
//
const CommissionGroupsIndex = () =>
  import("./general/commission-groups/index.vue");
// end
// start of financials
const ChartsIndex = () =>
  import("./financials/edit-chart-of-accounts/index.vue");
const EditCharts = () =>
  import("./financials/edit-chart-of-accounts/_pages/edit.vue");
//
const GlAccountIndex = () =>
  import("./financials/gl-account-determination/index.vue");
const CurrenciesIndex = () => import("./financials/currencies/index.vue");
const NewCurrency = () => import("./financials/currencies/_pages/create.vue");
// end of financials
// start of tax
const TaxGroupsIndex = () => import("./financials/tax/tax-groups/index.vue");
const NewTaxGroup = () =>
  import("./financials/tax/tax-groups/_pages/create.vue");
const WithholdingTaxIndex = () =>
  import("./financials/tax/withholding-tax/index.vue");
// end of tax

// start of business partners
const CountriesIndex = () => import("./business-partners/countries/index.vue");
const CustomerGroupIndex = () =>
  import("./business-partners/customer-groups/index.vue");
const NewCustomerGroup = () =>
  import("./business-partners/customer-groups/_pages/create.vue");
//
const VendorGroupIndex = () =>
  import("./business-partners/vendor-groups/index.vue");
const NewVendorGroup = () =>
  import("./business-partners/vendor-groups/_pages/create.vue");
//
const BpPropertiesIndex = () =>
  import("./business-partners/properties/index.vue");
const BpPropertiesChildren = () =>
  import("./business-partners/properties/_pages/children.vue");
const BpPropertiesCreate = () =>
  import("./business-partners/properties/_pages/create.vue");
const BpPropertiesCreateChildren = () =>
  import("./business-partners/properties/_pages/create-children.vue");
const PaymentTermsIndex = () =>
  import("./business-partners/payment-terms/index.vue");
const BranchDefaultsIndex = () =>
  import("./business-partners/branch-defaults/index.vue");
// end of business partners

// inventory
const ItemGroupsIndex = () => import("./inventory/item-groups/index.vue");
const NewItemGroup = () => import("./inventory/item-groups/_pages/create.vue");
const ViewItemGroup = () => import("./inventory/item-groups/_pages/view.vue");
// item properties
const ItemPropertiesIndex = () => import("./inventory/properties/index.vue");
const ItemPropertiesChildren = () =>
  import("./inventory/properties/_pages/children.vue");
const ItemPropertiesCreate = () =>
  import("./inventory/properties/_pages/create.vue");
const ItemPropertiesCreateChildren = () =>
  import("./inventory/properties/_pages/create-children.vue");
//
const WarehousesIndex = () => import("./inventory/warehouses/index.vue");
const NewWarehouse = () => import("./inventory/warehouses/_pages/create.vue");
const ViewWarehouse = () => import("./inventory/warehouses/_pages/view.vue");
//
const WarehouseTypesIndex = () =>
  import("./inventory/warehouse-types/index.vue");
const NewWarehouseType = () =>
  import("./inventory/warehouse-types/_pages/create.vue");
//
const UoMIndex = () => import("./inventory/UoM/index.vue");
const NewUoM = () => import("./inventory/UoM/_pages/create.vue");
//
const UoMGroupsIndex = () => import("./inventory/UoM-groups/index.vue");
const NewUoMGroup = () => import("./inventory/UoM-groups/_pages/create.vue");
const UoMGroupDefinition = () =>
  import("./inventory/UoM-groups/_pages/definition.vue");
//
const ManufacturersIndex = () => import("./inventory/manufacturers/index.vue");
const NewManufacturer = () =>
  import("./inventory/manufacturers/_pages/create.vue");
//
const ShippingTypesIndex = () => import("./inventory/shipping-types/index.vue");
const NewShippingType = () =>
  import("./inventory/shipping-types/_pages/create.vue");

//
const ItemDefaultsIndex = () => import("./inventory/item-defaults/index.vue");
const ItemDefaultsCreate = () =>
  import("./inventory/item-defaults/_pages/create.vue");
const ItemDefaultsView = () =>
  import("./inventory/item-defaults/_pages/view.vue");
// end of inventory

// banking
const BanksIndex = () => import("./banking/banks/index.vue");
const NewBank = () => import("./banking/banks/_pages/create.vue");
//
const HouseBankIndex = () => import("./banking/house-bank-accounts/index.vue");
const NewHouseBank = () =>
  import("./banking/house-bank-accounts/_pages/create.vue");
// end of banking

//vehicles
const VehiclesIndex = () => import("./general/vehicles/index.vue");
const VehiclesCreate = () => import("./general/vehicles/_pages/create.vue");
const VehiclesView = () => import("./general/vehicles/_pages/view.vue");
//end vehicles

//bin locations
const FieldActivationIndex = () =>
  import("./inventory/bin-locations/field-activation/index.vue");
const FieldActivationCreate = () =>
  import("./inventory/bin-locations/field-activation/_pages/create.vue");
const FieldActivationEdit = () =>
  import("./inventory/bin-locations/field-activation/_pages/view.vue");

const SubLevelsIndex = () =>
  import("./inventory/bin-locations/Sublevel-codes/index.vue");
const SubLevelsCreate = () =>
  import("./inventory/bin-locations/Sublevel-codes/_pages/create.vue");
const SubLevelsEdit = () =>
  import("./inventory/bin-locations/Sublevel-codes/_pages/view.vue");

const AttributesCodesIndex = () =>
  import("./inventory/bin-locations/attribute-codes/index.vue");
const AttributesCodesCreate = () =>
  import("./inventory/bin-locations/attribute-codes/_pages/create.vue");
const AttributesCodesEdit = () =>
  import("./inventory/bin-locations/attribute-codes/_pages/view.vue");

const WarehouseBinLocations = () =>
  import("./inventory/warehouses/_pages/bin-locations.vue");

const BinLocationInventory = () =>
  import("./inventory/warehouses/_pages/bin-inventory.vue");

//timesheets
const TimeSheetIndex = () => import("./general/time-sheets/index.vue");
const TimeSheetCreate = () => import("./general/time-sheets/_pages/create.vue");
const TimeSheetEdit = () => import("./general/time-sheets/_pages/view.vue");

export default [
  // banking
  {
    path: "/banking/banks",
    component: BanksIndex,
  },
  {
    path: "/banking/new-bank",
    component: NewBank,
  },
  {
    path: "/banking/house-bank-accounts",
    component: HouseBankIndex,
  },
  {
    path: "/banking/new-housebank-accounts",
    component: NewHouseBank,
  },
  // end of banking
  // start of general
  {
    path: "/users",
    component: UsersIndex,
  },
  {
    path: "/new-user",
    component: CreateUserPage,
  },
  {
    path: "/users/:id",
    component: UserDetailsPage,
  },
  {
    path: "/user-groups",
    component: UserGroupsIndex,
  },
  {
    path: "/sales-employees",
    component: SalesEmployeesIndex,
  },
  {
    path: "/sales-employees/:id",
    component: SalesEmployeesEdit,
  },
  {
    path: "/new-sales-employee",
    component: NewSalesEmployee,
  },
  {
    path: "/employees",
    component: EmployeesIndex,
  },
  {
    path: "/new-employee",
    component: NewEmployee,
  },
  {
    path: "/view-employee/:empID",
    component: ViewEmployee,
  },
  {
    path: "/employees/assignments/:empID",
    component: EmployeeAssignment,
    children: [
      {
        path: "",
        component: EmpWarehouses,
      },
      {
        path: "/employees/business-partners/:empID",
        component: EmpBusinessPartners,
      },
      {
        path: "/employees/payments/:empID",
        component: EmpPayments,
      },
      {
        path: "/employees/branches/:empID",
        component: EmpBranches,
      },

      {
        path: "/employees/items/:empID",
        component: ItemsAssignment,
      },
    ],
  },
  {
    path: "/drivers",
    component: DriversIndex,
  },
  {
    path: "/new-driver",
    component: NewDriver,
  },
  {
    path: "/view-driver/:id",
    component: ViewDriver,
  },
  {
    path: "/time-sheets",
    component: TimeSheetIndex,
  },
  {
    path: "/time-sheet/create",
    component: TimeSheetCreate,
  },
  {
    path: "/time-sheet/:id",
    component: TimeSheetEdit,
  },
  {
    path: "/setup/territories",
    component: TerritoriesIndex,
  },
  {
    path: "/setup/new-territory",
    component: NewTerritory,
  },
  {
    path: "/territory/:id",
    component: SubTerritory,
  },
  {
    path: "/commission-groups",
    component: CommissionGroupsIndex,
  },
  {
    path: "/setup/user-defaults",
    component: UserDefaultsIndex,
  },
  {
    path: "/user-defaults/create",
    component: CreateUserDefaults,
  },
  {
    path: "/user-defaults/:id",
    component: ViewUserDefaults,
  },

  {
    path: "/departments",
    component: DepartmentIndex,
  },
  {
    path: "/departments/create",
    component: CreateDepatment,
  },
  {
    path: "/departments/:id",
    component: ViewDepartment,
  },
  // end of general
  // start of financials
  {
    path: "/edit-chart-of-accounts",
    component: ChartsIndex,
  },
  {
    path: "/financials/chart-of-accounts-editing/:id",
    component: EditCharts,
  },
  {
    path: "/gl-account-determination",
    component: GlAccountIndex,
  },
  {
    path: "/currencies",
    component: CurrenciesIndex,
  },
  {
    path: "/new-currency",
    component: NewCurrency,
  },
  // end of financials
  {
    path: "/tax-groups",
    component: TaxGroupsIndex,
  },
  {
    path: "/new-tax-group",
    component: NewTaxGroup,
  },
  {
    path: "/withholding-tax",
    component: WithholdingTaxIndex,
  },
  // business partners
  {
    path: "/business-partners/countries",
    component: CountriesIndex,
  },
  {
    path: "/business-partners/customer-groups",
    component: CustomerGroupIndex,
  },
  {
    path: "/business-partners/new-customer-group",
    component: NewCustomerGroup,
  },
  {
    path: "/business-partners/vendor-groups",
    component: VendorGroupIndex,
  },
  {
    path: "/business-partners/new-vendor-group",
    component: NewVendorGroup,
  },
  {
    path: "/business-partners/properties",
    component: BpPropertiesIndex,
  },
  {
    path: "/bp/properties/create",
    component: BpPropertiesCreate,
  },
  {
    path: "/bp/properties/create/:id",
    component: BpPropertiesCreateChildren,
  },
  {
    path: "/bp/properties/:id",
    component: BpPropertiesChildren,
  },
  {
    path: "/business-partners/payment-terms",
    component: PaymentTermsIndex,
  },
  {
    path: "/branch-defaults",
    component: BranchDefaultsIndex,
  },
  // start of inventory
  {
    path: "/inventory/item-groups",
    component: ItemGroupsIndex,
  },
  {
    path: "/inventory/new-item-group",
    component: NewItemGroup,
  },
  {
    path: "/view-item-group/:id",
    component: ViewItemGroup,
  },
  {
    path: "/inventory/item-properties",
    component: ItemPropertiesIndex,
  },
  {
    path: "/item/properties/create",
    component: ItemPropertiesCreate,
  },
  {
    path: "/item/properties/create/:id",
    component: ItemPropertiesCreateChildren,
  },
  {
    path: "/item/properties/:id",
    component: ItemPropertiesChildren,
  },
  {
    path: "/inventory/warehouses",
    component: WarehousesIndex,
  },
  {
    path: "/inventory/warehouse/create",
    component: NewWarehouse,
  },
  {
    path: "/view-warehouse/:id",
    component: ViewWarehouse,
  },
  {
    path: "/inventory/warehouse-types",
    component: WarehouseTypesIndex,
  },
  {
    path: "/inventory/new-warehouse-type",
    component: NewWarehouseType,
  },
  {
    path: "/inventory/uom",
    component: UoMIndex,
  },
  {
    path: "/inventory/new-uom",
    component: NewUoM,
  },
  {
    path: "/inventory/uom-groups",
    component: UoMGroupsIndex,
  },
  {
    path: "/inventory/new-uom-group",
    component: NewUoMGroup,
  },
  {
    path: "/uom-group/definition/:id",
    component: UoMGroupDefinition,
  },
  {
    path: "/inventory/manufacturers",
    component: ManufacturersIndex,
  },
  {
    path: "/inventory/new-manufacturer",
    component: NewManufacturer,
  },
  {
    path: "/inventory/shipping-types",
    component: ShippingTypesIndex,
  },
  {
    path: "/inventory/new-shipping-type",
    component: NewShippingType,
  },
  {
    path: "/inventory/item-defaults",
    component: ItemDefaultsIndex,
  },
  {
    path: "/inventory/item-defaults/create",
    component: ItemDefaultsCreate,
  },
  {
    path: "/inventory/item-defaults/:id",
    component: ItemDefaultsView,
  },
  //BIN LOCATIONS
  {
    path: "/inventory/bin-locations/field-activation",
    component: FieldActivationIndex,
  },
  {
    path: "/inventory/bin-locations/field-activation/create",
    component: FieldActivationCreate,
  },
  {
    path: "/inventory/bin-locations/field-activation/:id",
    component: FieldActivationEdit,
  },
  {
    path: "/inventory/bin-locations/sublevel-codes",
    component: SubLevelsIndex,
  },
  {
    path: "/inventory/bin-locations/sublevel_codes/create",
    component: SubLevelsCreate,
  },
  {
    path: "/inventory/bin-locations/sublevel_codes/:id",
    component: SubLevelsEdit,
  },

  {
    path: "/inventory/bin-locations/attribute-code",
    component: AttributesCodesIndex,
  },
  {
    path: "/inventory/bin-locations/attribute-codes/create",
    component: AttributesCodesCreate,
  },
  {
    path: "/inventory/bin-locations/attribute-code/:id",
    component: AttributesCodesEdit,
  },
  {
    path: "/warehouse/bin-location/:WhsCode",
    component: WarehouseBinLocations,
  },
  {
    path: "/bin-locations/:id/inventory",
    component: BinLocationInventory,
  },

  // end of inventory
  {
    path: "/vehicles",
    component: VehiclesIndex,
  },
  {
    path: "/vehicle/create",
    component: VehiclesCreate,
  },
  {
    path: "/vehicle/:id",
    component: VehiclesView,
  },
];
