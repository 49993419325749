const Index = () => import('./index');
const Tiers = () => import('./tiers/_pages/tiers')
const TierDetails = () => import('./tiers/_pages/view')
const channels = () => import('./channels/_pages/channels')
const ChannelDetails = () => import('./channels/_pages/view')
const CreateChannel = () => import('./channels/_pages/create')
const CreateTier = () => import('./tiers/_pages/create')
export default [
    {
        path: '/channels',
        component: Index,
        children: [
            {
                path: '',
                component: channels
            },
            {
                path: 'tiers',
                component: Tiers
            },
        ]
    },
    {
        path: '/channels/:id',
        component: ChannelDetails
    },
    {
        path: '/tiers/:id',
        component: TierDetails
    },
    {
        path: '/new-channel',
        component: CreateChannel
    },
    {
        path: '/new-tier',
        component: CreateTier
    }

];