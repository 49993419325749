<template>
    <v-chart class="chart" id="main" :option="option" autoresize />    
</template>

<script>
    import * as echarts from 'echarts/core';
    import { GridComponent } from 'echarts/components';
    import { BarChart } from 'echarts/charts';
    import { CanvasRenderer } from 'echarts/renderers';
    import { defineComponent, computed } from 'vue';
    import VChart,{ THEME_KEY } from 'vue-echarts';

    echarts.use([
    CanvasRenderer,
    BarChart,
    GridComponent,
    ]);
    

    export default defineComponent({
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    components: {
        VChart,
    },
    provide: {
        [THEME_KEY]: 'light',
    },
    setup(props) {
      const option = computed(() => {        
        return {
            title:{
                text: 'Quantities Summaries',
                subtext: 'Last Month',
                left: 'center',
                top: 'top',
                textStyle: {
                    fontSize: 16,
                    fontWeight: 'bold',
                },
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                type: 'shadow'
                }
            },
            xAxis: {
                type: 'category',
                data: props.data.labels,
                axisTick: {
                    alignWithLabel: true
                },
                axisLabel: {
                // Enable automatic label rotation
                rotate: 45, // Adjust the rotation angle (degrees)
                overflow: 'ellipsis', // Trim long labels with ellipsis (...)
                }
            },
            yAxis: {
                type: 'value',
            },
            series: props.data.datasets.map(dataset => ({
                type: 'bar',
                name: dataset.label,
                data: dataset.data,
                barWidth: '60%',
                itemStyle: {
                color: dataset.backgroundColor,
                },
            })),
        };
      });
    
      return { option };
    },
    mounted() {
      // Force re-render on mount
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    }
    });
</script>