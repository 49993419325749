const LeaseAgreementIndex = () => import("./lease-agreement/index");
const NewLeaseAgreement = () => import("./lease-agreement/_pages/create");
const LeaseAgreementRecord = () => import("./lease-agreement/_pages/view");

// recurrent templates
const RecurrentTemplateIndex = () => import("./recurrent-templates/index");
const NewRecurrentTemplate = () =>
  import("./recurrent-templates/_pages/create");

// sales doc general routes
//const SalesDocIndex = () => import("./sale-docs/index");
const SalesDocIndex = () => import("../sales/sale-docs/index.vue");
const SalesDocDetails = () => import("../sales/sale-docs/_pages/view");
//const SalesDocCreate = () => import("./sale-docs/_pages/create");
const SalesDocCreate = () => import("../sales/sale-docs/_pages/create");
const SalesDocCopy = () => import("../sales/sale-docs/_pages/copy");
export default [
  // lease agreement
  {
    path: "/purchasing/blanket-agreement",
    component: LeaseAgreementIndex,
  },
  {
    path: "/purchasing/new-blanket-agreement",
    component: NewLeaseAgreement,
  },
  {
    path: "/lease-agreement-record/:id",
    component: LeaseAgreementRecord,
  },
  {
    path: "/purchasing/doc/:objectId",
    component: SalesDocIndex,
  },
  {
    path: "/purchasing/doc/create/:objectId",
    component: SalesDocCreate,
  },
  {
    path: "/purchasing/doc/copy/:baseObject/:docId/:copyToDoc",
    component: SalesDocCopy,
  },
  {
    path: "/purchasing/doc/:objectId/:id",
    component: SalesDocDetails,
  },
  // lease agreement
  // Recurrent Templates
  {
    path: "/purchasing/recurrent-templates",
    component: RecurrentTemplateIndex,
  },
  {
    path: "/purchasing/new-recurrent-template",
    component: NewRecurrentTemplate,
  },
];
