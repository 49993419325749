<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="7" md="7">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>LEYSCO100</v-toolbar-title>
            <v-spacer />
            <v-icon>mdi-bank</v-icon>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6" style="align-text: center">
                <v-img src="@/assets/leyscologo.png"></v-img>
              </v-col>

              <v-col cols="12" md="6">
                Login here or create an account
                <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                  <v-text-field
                    label="Email"
                    v-model="user.email"
                    :rules="inputRules"
                    prepend-icon="mdi-account-outline"
                    type="text"
                  />

                  <v-text-field
                    id="password"
                    label="Password"
                    name="password"
                    v-model="user.password"
                    :rules="inputRules"
                    autocomplete="on"
                    prepend-icon="mdi-lock"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                  />
                </v-form>
                <v-row no-gutters>
                  <v-btn color="primary" class="right" @click="login" :loading="loader"
                    >Login</v-btn
                  >
                  <v-spacer></v-spacer>
                  <!-- <v-btn color="accent" to="/register" text>Or Register</v-btn> -->
                </v-row>

                <v-row class="pt-3">
                  <v-btn color="primary" text small to="/forgot/password"
                    >Forgot password? Reset</v-btn
                  >
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions></v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- start of snackbar -->
    <snackbar ref="snackbar"></snackbar>
    <!-- end of snackbar -->
  </v-container>
</template>

<script>
import AuthLayout from "@/layout/auth.vue";
// import axios from "axios";
import { mapGetters } from "vuex";
export default {
  layout: AuthLayout,
  data: () => ({
    lazy: false,
    valid: false,
    loader: false,
    user: {},
    showPassword: false,
    inputRules: [(v) => !!v || "Required"],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
  }),
  methods: {
    setUserData(user) {
      this.$store.commit("setUser", user);
    },
    login() {
      this.$store.commit("loader", false);
      if (this.$refs.form.validate()) {
        const url = "/login";
        const data = this.user;
        this.loader = true;
        const self = this;
        this.$store
          .dispatch("post", { url, data })
          .then((res) => {
            if (res.statusCode == 0) {
              //first clear all local storage data
              self.$store.commit("RESET_STATE");
              self.$store.commit("salesEmployee/clearData");
              self.$store.commit("documentFormSetting/clearData");
              self.$store.commit("ohem/clearData");
              self.$store.commit("currency/clearData");
              self.$store.commit("globalWarehouses/clearData");
              //done clearing local storage data

              localStorage.setItem("accessToken", res.access_token);
              localStorage.setItem("token", res.access_token);
              localStorage.setItem("stoken", res.access_token);
              self.$store.commit("setUser", res.user);
              self.$store.commit("setUserBranch", res.branches);
              self.$store.commit("setAllUserData", res.userdata);

              //check for local settings
              self.useLocalSearch(res.user);
              self.loader = false;
              this.fetchMenuData(res.user.id);
            } else {
              self.loader = false;
              this.$refs.snackbar.show(res.message, "red");
            }
          })
          .catch((err) => {
            console.log(err);
            self.loader = false;
            this.$refs.snackbar.show(err, "red");
          });
      }
    },
    useLocalSearch(data) {
      const self = this;
      const userData = data;
      let localSearchSettings = {};

      localSearchSettings.enable = false;
      localSearchSettings.localurl = null;

      if (userData.oadm.useLocalSearch) {
        localSearchSettings.enable = true;
        localSearchSettings.localurl = userData.oadm.localUrl;
      }

      if (userData.useLocalSearch) {
        localSearchSettings.enable = true;
        localSearchSettings.localurl = userData.localUrl;
      }

      if (!userData.oadm.useLocalSearch && userData.useLocalSearch) {
        localSearchSettings.enable = true;
        localSearchSettings.localurl = userData.localUrl;
      }

      self.$store.commit("setLocalSettings", localSearchSettings);
    },
    fetchMenuData(UserId) {
      const self = this;
      this.$store
        .dispatch("get", `/form_settings_menu/user/${UserId}`)
        .then((res) => {
          this.$store.commit("setMenuData", res.AllTreeview);
          self.$router.push("/dashboard");
          self.fetchedInitialData();
        })
        .catch((err) => {
          console.log(err);
          this.$refs.snackbar.show("Error Getting Form settings Menu", "red");
        });
    },

    async fetchedInitialData() {
      // get data
      ///const _loadTerritories = this.$store.dispatch("get", "/territories");
      const _loadCurrencies = this.$store.dispatch("get", "/currency");
      const _loadSalesEmployees = this.$store.dispatch("get", "/employee");
      const _loadUoms = this.$store.dispatch("get", "/uom");
      // const _loadSurveys = this.$store.dispatch("get", "/surveys");
      const _loadEmployeeData = this.$store.dispatch("get", "/employee-master-data");
      const _loadWarehouseData = this.$store.dispatch("get", "/warehouse");
      const _loadOutputTaxGroups = this.$store.dispatch("get", "/taxgroups/output");
      const _loadInputTaxGroups = this.$store.dispatch("get", "/taxgroups/input");
      const _loadPriceList = this.$store.dispatch("get", "/price_lists");
      const _loadCreditCards = this.$store.dispatch("get", "/credit-card");
      const _loadGlAccounts = this.$store.dispatch("get", "/activeGLaccounts");
      const _loadChecksGlAccounts = this.$store.dispatch(
        "get",
        "/activeGLaccounts?type=Checks"
      );
      const _loadRevenueGlAccounts = this.$store.dispatch(
        "get",
        "/activeGLaccounts?type=Revenue"
      );
      const _loadSalesOrderFormSettings = this.$store.dispatch(
        "get",
        "/form_settings/17"
      );
      const _loadInvoiceFormSettings = this.$store.dispatch("get", "/form_settings/13");
      const _loadDistributionRules1 = this.$store.dispatch(
        "get",
        "/distribution-rules/1"
      );
      const _loadDistributionRules2 = this.$store.dispatch(
        "get",
        "/distribution-rules/2"
      );
      const _loadDistributionRules3 = this.$store.dispatch(
        "get",
        "/distribution-rules/3"
      );
      const _loadDistributionRules4 = this.$store.dispatch(
        "get",
        "/distribution-rules/4"
      );
      const _loadDistributionRules5 = this.$store.dispatch(
        "get",
        "/distribution-rules/5"
      );

      // const _loadPurchaseRequestFormSetting = this.$store.dispatch(
      //   "get",
      //   "/employee-master-data"
      // );

      // create promises
      const promises = [
        //_loadTerritories,
        _loadCurrencies,
        _loadSalesEmployees,
        _loadUoms,
        // _loadSurveys,
        _loadEmployeeData,
        _loadWarehouseData,
        _loadOutputTaxGroups,
        _loadInputTaxGroups,
        _loadPriceList,
        _loadCreditCards,
        _loadGlAccounts,
        _loadChecksGlAccounts,
        _loadRevenueGlAccounts,
        _loadSalesOrderFormSettings,
        _loadInvoiceFormSettings,
        _loadDistributionRules1,
        _loadDistributionRules2,
        _loadDistributionRules3,
        _loadDistributionRules4,
        _loadDistributionRules5,

        // _loadPurchaseRequestFormSetting,
      ];
      // declare data from promises
      const [
        //territoriesData,
        currenciesData,
        salesEmployeesData,
        uomsData,
        // surveysData,
        employeeMasterData,
        warehouseData,
        outputTaxGroups,
        inputTaxGroups,
        priceList,
        creditCards,
        glAccounts,
        checksGlAccounts,
        revenueGlAccounts,
        salesOrderFormSettings,
        invoiceFormSettings,
        distributionRules1,
        distributionRules2,
        distributionRules3,
        distributionRules4,
        distributionRules5,
        //purchaseRequestFormSetting,
      ] = await Promise.all(promises);
      // commit data to store
      // if (territoriesData) {
      //   this.$store.commit("territory/loadData", territoriesData.ResponseData);
      // }

      // if (menuData) {
      //   this.$store.commit("setMenuData", menuData.AllTreeview);
      // }

      var distRules = { ...this.distributionRules };
      var savedGlAccounts = { ...this.getSavedGlAccounts };

      var newDocumentFormSetting = { ...this.getDocumentFormSetting };
      // var newDocumentModel = { ...this.getDocumentModel };

      if (currenciesData) {
        this.$store.commit("currency/loadData", currenciesData);
      }

      if (salesEmployeesData) {
        this.$store.commit("salesEmployee/loadData", salesEmployeesData.ResponseData);
      }

      if (uomsData) {
        this.$store.commit("uom/loadData", uomsData);
      }

      // if (surveysData) {
      //   this.$store.commit("survey/loadData", surveysData);
      // }

      if (employeeMasterData) {
        this.$store.commit("ohem/loadData", employeeMasterData.ResponseData);
      }
      if (warehouseData) {
        this.$store.commit("setWarehouseData", warehouseData.ResponseData);
      }
      if (outputTaxGroups) {
        this.$store.commit("setOutputTaxGroups", outputTaxGroups);
      }
      if (inputTaxGroups) {
        this.$store.commit("setInputTaxGroups", inputTaxGroups);
      }
      if (priceList) {
        this.$store.commit("setPriceList", priceList.ResponseData);
      }
      if (creditCards) {
        this.$store.commit("setCreditCards", creditCards.ResponseData);
      }
      if (glAccounts) {
        savedGlAccounts["Default"] = glAccounts;
        this.$store.commit("setActiveGlAcounts", savedGlAccounts);
      }
      if (checksGlAccounts) {
        savedGlAccounts["Checks"] = checksGlAccounts;
        this.$store.commit("setActiveGlAcounts", savedGlAccounts);
      }
      if (revenueGlAccounts) {
        savedGlAccounts["Revenue"] = revenueGlAccounts;
        this.$store.commit("setActiveGlAcounts", savedGlAccounts);
      }
      if (salesOrderFormSettings) {
        newDocumentFormSetting[17] = salesOrderFormSettings;
        this.$store.commit("documentFormSetting/loadData", newDocumentFormSetting);
      }
      if (invoiceFormSettings) {
        newDocumentFormSetting[13] = invoiceFormSettings;
        this.$store.commit("documentFormSetting/loadData", newDocumentFormSetting);
      }
      if (distributionRules1) {
        // var distRules = { ... this.distributionRules}
        distRules[1] = distributionRules1.ResponseData;
        this.$store.commit("setDistributionRules", distRules);
      }
      if (distributionRules2) {
        // var distRules2 = { ... this.distributionRules}
        distRules[2] = distributionRules2.ResponseData;
        this.$store.commit("setDistributionRules", distRules);
      }
      if (distributionRules3) {
        // var distRules2 = { ... this.distributionRules}
        distRules[3] = distributionRules3.ResponseData;
        this.$store.commit("setDistributionRules", distRules);
      }
      if (distributionRules4) {
        // var distRules2 = { ... this.distributionRules}
        distRules[4] = distributionRules4.ResponseData;
        this.$store.commit("setDistributionRules", distRules);
      }
      if (distributionRules5) {
        // var distRules5 = { ... this.distributionRules}
        distRules[5] = distributionRules5.ResponseData;
        this.$store.commit("setDistributionRules", distRules);
      }
    },

    documentFormSettings(objectId) {
      const self = this;
      self.loading = true;
      this.$store
        .dispatch("get", `/form_settings/${objectId}`)
        .then((res) => {
          var newDocumentFormSetting = { ...this.getDocumentFormSetting };
          newDocumentFormSetting[objectId] = res;
          this.$store.commit("documentFormSetting/loadData", newDocumentFormSetting);
        })
        .catch((err) => {
          this.$store.commit("loader", false);
          console.log(err, "error");
        });

      this.$store
        .dispatch("get", `/doc_model/${objectId}`)
        .then((res) => {
          var newDocumentModel = { ...this.getDocumentModel };
          newDocumentModel[objectId] = res;
          this.$store.commit("setDocumentModel", newDocumentModel);
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  computed: {
    ...mapGetters({
      distributionRules: "getDistributionRules",
      getSavedGlAccounts: "getActiveGlAccounts",
      getDocumentFormSettings: "documentFormSetting/getDocumentFormSettings",
      getDocumentModel: "getDocumentModel",
    }),
  },

  created() {
    // localStorage.removeItem("apiBaseUrl");
    // const host = window.location.host;
    // const url = "https://apis-director.leysco100.com/api/v1/api-details?f=" + host;
    // axios
    //   .get(url, this.options)
    //   .then((res) => {
    //     // localStorage.setItem("apiBaseUrl", res.data.baseUrl);
    //     console.log(res)
    //     // localStorage.setItem("apiBaseUrl", "https://my108-be.leysco100.com/api/v1");
    //   })
    // .catch((err) => {
    //     console.log(err, "error");
    //   });
    // const host = window.location.host;
    // if (host == "localhost:8080"){
    //   localStorage.setItem("apiBaseUrl", "http://localhost:8000/api/v1");
    // }else{
    //   var subdomains = host.split(".");
    //   subdomains[0] = "https://"+subdomains[0] + "-be"
    //   const baseUrl = subdomains.join(".")+"/api/v1"
    //   localStorage.setItem("apiBaseUrl", baseUrl);
    // }
  },
  mounted() {
    const apiBaseUrl = localStorage.getItem("apiBaseUrl");
    const host = window.location.host;
    if (host == "localhost:8080") {
      localStorage.setItem("apiBaseUrl", "http://dev100.localhost:8000/api/v1");
    } else {
      var subdomains = host.split(".");
      subdomains[0] = "https://" + subdomains[0] + "-be";
      const baseUrl = subdomains.join(".") + "/api/v1";
      localStorage.setItem("apiBaseUrl", baseUrl);
    }
    if (apiBaseUrl == null) {
      location.reload();
    }
  },
};
</script>
