
export default {
  setUser(state, user) {
    state.user = user;
  },
  setUsers(state, users) {
    state.users = users;
  },
  setUserBranch(state, branch) {
    state.branches = branch;
  },
  setAllUserData(state, userdata) {
    state.userdata = userdata;
  },
  setMenuData(state, menuData) {
    state.menuData = menuData;
  },
  setWarehouseData(state, warehouseData) {
    state.warehouses = warehouseData;
  },
  setOutputTaxGroups(state, taxGroupsData) {
    state.outputTaxGroups = taxGroupsData;
  },
  setInputTaxGroups(state, taxGroupsData) {
    state.inputTaxGroups = taxGroupsData;
  },
  setPriceList(state, priceList) {
    state.priceList = priceList;
  },
  setCreditCards(state, creditCards) {
    state.creditCards = creditCards;
  },
  setActiveGlAcounts(state, glAccounts) {
    state.activeGlAccounts = glAccounts;
  },
  setDistributionRules(state, distributionRules) {
    state.distributionRules = distributionRules;
  },
  setBranchDistributionRules(state, branchDistributionRules) {
    state.branchDistributionRules = branchDistributionRules;
  },
  setDocumentModel(state, documentModel) {
    state.documentModel = documentModel;
  },
  setDocumentRights(state, documentRights) {
    state.docRights = documentRights;
  },
  setDefaultSettings(state, defaultSettings) {
    state.defaultSettings = defaultSettings;
  },
  setUomGroups(state, uomGroups) {
    state.uomGroups = uomGroups;
  },
  loader(state, payload) {
    state.loader = payload;
  },
  setLocalSettings(state, payload) {
    state.localSettings = payload;
  },

  RESET_STATE(state) {
    // Reset the state properties to their default values

    state.outputTaxGroups =  [];
    state.inputTaxGroups =  [];
    state.inputTaxGroups =  [];
    state.priceList =  [];
    state.creditCards =  [];
    state.activeGlAccounts =  {};
    state.distributionRules =  {};
    state.branchDistributionRules =  {};
    state.defaultSettings =  {};
    state.documentModel =  {};
    state.docRights =  {};
    state.uomGroups =  {};
    state.clients = [];
    state.user = null;
    state.branches = [];
    state.userdata = [];
    state.menuData = [];
    state.warehouses = [];
    state.isLoggedIn = false;
    state.host = "";
    state.loader = false;
    state.localSearchSettings = {};
  },
};
