const PriceListIndex = () => import("./price-list/index");
const NewPriceList = () => import("./price-list/_pages/create");
const ViewPriceList = () => import("./price-list/_pages/view.vue");
// master data
const ItemMasterDataIndex = () => import("./item-master-data/index");
const NewInventoryMasterData = () => import("./item-master-data/_pages/create");
const ItemMasterDataDetails = () => import("./item-master-data/_pages/view");
// transactions
const TransactionIndex = () => import("./transactions/index");
const TransactionCreate = () => import("./transactions/_pages/create");
const TransactionDetails = () => import("./transactions/_pages/view.vue");
const InventoryDocCopy = () => import("./transactions/_pages/copy");

const StockBalancesReport = () => import("./reports/stock-balances/index.vue");
const SerialNumbersReport = () => import("./reports/serials/index.vue");
const BinLocationsReport = () => import("./reports/bin_locations/index.vue");

// const SalesDocIndex = () => import("../sales/sale-docs/index.vue");
// const SalesDocDetails = () => import("../sales/sale-docs/_pages/view");
// const SalesDocCreate = () => import("../sales/sale-docs/_pages/create");
// const SalesDocCopy = () => import("../sales/sale-docs/_pages/copy");
//BIN LOCATIONS
const BinLocationIndex = () => import("./bin-locations/index.vue");
const BinLocationCreate = () => import("./bin-locations/_pages/create");
const BinLocationDetails = () => import("./bin-locations/_pages/view.vue");

//Period and volume discounts
const PeriodAndVolDiscountsIndex = () =>
  import("./period-and-vol-discounts/main/index.vue");
const PeriodAndVolDiscountsCreate = () =>
  import("./period-and-vol-discounts/main/_pages/create.vue");

const PeriodAndVolDiscountsView = () =>
  import("./period-and-vol-discounts/main/_pages/view.vue");

const PeriodDiscountsIndex = () =>
  import("./period-and-vol-discounts/period-discounts/index.vue");

const VolumeDiscountsIndex = () =>
  import("./period-and-vol-discounts/volume-discounts/index.vue");

//Discount groups
const DiscountGroupsIndex = () => import("./discount-groups/index.vue");
const DiscountGroupsItems = () => import("./discount-groups/items/index.vue");
const DiscountGroupsItemsCreate = () =>
  import("./discount-groups/items/_pages/create.vue");
const DiscountGroupsItemsview = () =>
  import("./discount-groups/items/_pages/view.vue");

const DiscountGroupsItemGroup = () =>
  import("./discount-groups/item-groups/index.vue");

const DiscountGroupsItemGroupCreate = () =>
  import("./discount-groups/item-groups/_pages/create.vue");
const DiscountGroupsItemGroupView = () =>
  import("./discount-groups/item-groups/_pages/view.vue");

const PromotionItemsIndex = () => import("./promotion-items/index.vue");
const PromotionItemsCreate = () => import("./promotion-items/_pages/create.vue");
const PromotionItemsView = () => import("./promotion-items/_pages/view.vue");


export default [
  {
    path: "/inventory/price-list",
    component: PriceListIndex,
  },
  {
    path: "/inventory/new-price-list",
    component: NewPriceList,
  },
  {
    path: "/inventory/view-price-list/:id",
    component: ViewPriceList,
  },
  {
    path: "/inventory/period-and-vol-discounts",
    component: PeriodAndVolDiscountsIndex,
  },
  {
    path: "/inventory/period-discounts/:ListNum/item",
    component: PeriodAndVolDiscountsCreate,
  },

  {
    path: "/inventory/discount-groups",
    component: DiscountGroupsIndex,
    children: [
      {
        path: "",
        component: DiscountGroupsItems,
      },
      {
        path: "/inventory/discount-group/item-groups",
        component: DiscountGroupsItemGroup,
      },
    ],
  },
  {
    path: "/discount-groups/items/create",
    component: DiscountGroupsItemsCreate,
  },
  {
    path: "/discount-groups/items/:id",
    component: DiscountGroupsItemsview,
  },

  {
    path: "/discount-group/item-groups/create",
    component: DiscountGroupsItemGroupCreate,
  },
  {
    path: "/discount-group/item-groups/:id",
    component: DiscountGroupsItemGroupView,
  },

  {
    path: "/period-and-vol-discount/:id",
    component: PeriodAndVolDiscountsView,
  },

  {
    path: "/inventory/period-discounts/:ItemCode/:ListNum/:LINENUM",
    component: PeriodDiscountsIndex,
  },
  {
    path: "/inventory/volume-discounts/create/:SPP1LNum",
    component: VolumeDiscountsIndex,
  },

  // master data
  {
    path: "/inventory/item-master-data",
    component: ItemMasterDataIndex,
  },
  {
    path: "/inventory/new-item-master-data",
    component: NewInventoryMasterData,
  },
  {
    path: "/item-master-data/:id",
    component: ItemMasterDataDetails,
  },
  // transactions
  {
    path: "/inventory/transactions/:objectId",
    component: TransactionIndex,
  },
  {
    path: "/inventory/transactions/create/:objectId",
    component: TransactionCreate,
  },
  {
    path: "/inventory/transactions/view/:objectId/:id",
    component: TransactionDetails,
  },
  {
    path: "/inventory/doc/copy/:baseObject/:docId/:copyToDoc",
    component: InventoryDocCopy,
  },
  //Reports
  {
    path: "/inventory/report/",
    component: StockBalancesReport,
  },
  {
    path: "/inventory/report/serial-numbers-report",
    component: SerialNumbersReport,
  },
  {
    path: "/bin-locations/reports",
    component: BinLocationsReport,
  },
  // {
  //   path: "/inventory/transactions/copy/:baseObject/:docId/:copyToDoc",
  //   component: SalesDocCopy,
  // },
  // {
  //   path: "/inventory/transactions/:objectId/:id",
  //   component: SalesDocDetails,
  // },

  {
    path: "/inventory/bin-locations",
    component: BinLocationIndex,
  },
  {
    path: "/inventory/bin-locations/create",
    component: BinLocationCreate,
  },
  {
    path: "/ware-house/bin-locations/:id",
    component: BinLocationDetails,
  },
  {
    path: "/inventory/promotion-items",
    component: PromotionItemsIndex,
  },
  {
    path: "/inventory/promotion-items/create",
    component: PromotionItemsCreate,
  },
  {
    path: "/inventory/promotion-items/:id",
    component: PromotionItemsView,
  },
];
