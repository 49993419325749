const Index = () => import("./index");
const CreatePage = () => import("./_pages/create");
const TargetDetails = () => import("./_pages/details");
const TargetShowDetails = () => import("./_pages/target-show");
const TargetTabs = () => import("./target_tabs");
//const EditPage = () => import("./_pages/edit");
const SkuPage = () => import("./_pages/target_skus");
const TargetSlp = () => import("./_pages/target_slp");
const SlpTargets = () => import("./slp_targets/_pages/index");
const TargetsReports = () => import("../targets/reports/index.vue");

export default [
  {
    path: "/sales-targets-reports",
    component: TargetsReports,
  },
  {
    path: "/targets",
    component: Index,
  },
  {
    path: "/targets/:id",
    component: TargetDetails,
  },
  {
    path: "/new-target",
    component: CreatePage,
  },
  {
    path: "/targets/slp_targets/:id",
    component: SlpTargets,
  },
  {
    path: "/target/:id",
    component: TargetTabs,
    children: [
      {
        path: "",
        component: TargetShowDetails,
      },
      {
        path: "employees",
        component: TargetSlp,
      },
      // {
      //   path: '/target_tabs/:id',
      //   component: EditPage,
      // },
      {
        path: "skus",
        component: SkuPage,
      },
    ],
  },
];
