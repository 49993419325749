const Index = () => import('./index');
const CallCompletion = () => import('./_pages/call-completion/summary');
const CallBySalesRep = () => import('./_pages/call-completion/sales-rep');
const MappedOutlets = () => import('./_pages/mapped-outlets/summary');

export default [
    {
        path: '/reports',
        component: Index,
    },
    {
        path: '/reports/call-completion',
        component: CallCompletion
    },
    {
        path: '/reports/call-completion-per-user',
        component: CallBySalesRep
    },
    {
        path: '/reports/mapped-outlets',
        component: MappedOutlets
    },
];