<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="8">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>LEYSCO100</v-toolbar-title>
            <v-spacer />
            <v-icon>mdi-bank</v-icon>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <!-- <v-img
                  style="margin-top: 40px"
                  src="@/assets/stock.png"
                ></v-img> -->
              </v-col>

              <v-col cols="12" md="6" align-self="center">
                Reset password
                <v-form>
                  <v-text-field
                    label="Email"
                    v-model="user.email"
                    prepend-icon="mdi-account-outline"
                    :rules="[emailRules]"
                    type="email"
                    required
                  />
                </v-form>
                <v-row no-gutters>
                  <v-btn color="accent" to="/login">Login</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" class="right" @click="login"
                    >Reset</v-btn
                  >
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions></v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- start of snackbar -->
    <snackbar ref="snackbar"></snackbar>
    <!-- end of snackbar -->
  </v-container>
</template>
<script>
export default {
  data: () => ({
    showPassword: false,
    user: {},
    emailRules: [
      (v) => !!v || "Email is required",
      (v) => /.+@.+\..+/.test(v) || "Email must be valid",
    ],
  }),
  methods: {
    login() {
      const host = window.location.host;
      console.log(this.user);
      this.$store.commit("loader", false);
      const url = "reset-password?f=" + host;
      const data = this.user;

      this.loader = true;
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            this.$refs.snackbar.show(res.ResponseData, "green");
          }
          if (res.ResultCode == 1500) {
            this.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          console.log(err);
          self.loader = false;
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
};
</script>
