import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import "./assets/styles.css";

import router from "./routes/router";
import store from "./store/store";
// layouts
import DefaultLayout from "./layout/default.vue";
import PosLayout from "./layout/pos.vue";
import AdminLayout from "./layout/admin.vue";
// import AuthLayout from './layout/auth.vue';

// socket.io imports
// import socketio from 'socket.io-client';
// import VueSocketIO from 'vue-socket.io';
// end

// reusable components
import Snackbar from "@/components/global/snackbar.vue";
import DatePicker from "@/components/global/datepicker.vue";
import DashPicker from "@/components/dashboard/datepicker.vue";
import DocDatePicker from "@/components/global/doc-datepicker.vue";
import TimePicker from "@/components/global/timepicker.vue";
import TextField from "@/components/global/textfield.vue";
import TextArea from "@/components/global/textarea.vue";
import SelectField from "@/components/global/select.vue";
// marketing docs
import Radio from "@/components/global/radio.vue";
// reports exporting to files
// import JsonExcel from "vue-json-excel";
import pdf from "vue-pdf";
// import VueElementLoading from 'vue-element-loading'
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

import Chartkick from "vue-chartkick";
import Chart from "chart.js";

import * as VueGoogleMaps from "vue2-google-maps";
import refreshMixin from '@/mixins/refreshMixin';

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyB7r43uNpFPCaOlSJq_w6qh7yLPe1FUnQE",
    libraries: "places",
  },
});

Vue.use(Chartkick.use(Chart));
Vue.use(require("vue-moment"));
Vue.component("Snackbar", Snackbar);
Vue.component("DatePicker", DatePicker);
Vue.component("DashPicker", DashPicker);
Vue.component("DocDatePicker", DocDatePicker);
Vue.component("TimePicker", TimePicker);
Vue.component("TextField", TextField);
Vue.component("TextArea", TextArea);
Vue.component("SelectField", SelectField);
Vue.component("Radio", Radio);
// end of reusable
// Vue.component('AuthLayout', AuthLayout);
Vue.component("PosLayout", PosLayout);
Vue.component("AdminLayout", AdminLayout);
Vue.component("DefaultLayout", DefaultLayout);
// Vue.component("ExcelExport", JsonExcel);
Vue.component("ExportPDF", pdf);
Vue.component("PulseLoader", PulseLoader);

Vue.config.productionTip = false;

Vue.mixin(refreshMixin);

new Vue({
  data: {
    globalRefreshMessage: refreshMixin.data().refreshMessage,
  },
  vuetify,
  router,
  store,
  // apolloProvider,
  render: (h) => h(App),
}).$mount("#app");

