<template>
    <v-container fluid>
      <v-form ref="form" :formSetup="setup" :model="formSetup.model">
        <div v-for="(panel, index) in formSetup.panels" v-bind:key="index">
          <v-row v-if="panel.displayType == 'inputs'">
            <v-col
              v-for="(dataField, i) in panel.inputs"
              v-bind:key="i"
              :cols="dataField.columnWidth"
            >
              <v-text-field
                v-if="dataField.fieldType == 'textfield'"
                v-model="record[dataField.model]"
                :label="dataField.label"
                :readonly="dataField.readonly"
                :type="dataField.type"
                @click="handleClick(dataField.clickMethod, dataField.label)"
              ></v-text-field>
              <!-- radio -->
              
              <text-area v-if="dataField.fieldType == 'textarea'" :setup="dataField"></text-area>

              <v-autocomplete
                v-show="dataField.fieldType == 'select'"
                v-model="record[dataField.model]"
                :items="getData(dataField.data)"
                :item-text="dataField.itemText"
                :item-value="dataField.itemValue"
                :label="dataField.label"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row></v-row>

          <v-row v-if="panel.displayType == 'tabs'">
            <v-col cols="12">
              <v-tabs background-color="accent" color="white">
                <!-- loop tabs -->
                <v-tab v-for="(tab, index) in panel.tabs" v-bind:key="index">{{tab.name}}</v-tab>
                <!-- loop tab items -->
                <v-tab-item v-for="(tab, index) in panel.tabs" v-bind:key="index">{{tab.contents}}</v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </div>

        <div>
          <v-btn color="primary" small @click="save(formSetup.model)">Save</v-btn>
        </div>
      </v-form>
    </v-container>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      fieldProps: {
        label: "Name"
      },
      record: {},
      uoms: [{ name: "peter" }, { name: "Karanja" }],
      setup: {},
      formSetup: {
        formId: 1,
        ref: "form",
        model: "form1",
        formName: "A/R Invoice",
        panels: [
          {
            id: 1,
            displayType: "inputs",
            inputs: [
              {
                fieldType: "textfield",
                columnWidth: 6,
                label: "Name",
                readonly: false,
                position: 2,
                model: "age",
                clickMethod: "testClick"
              },
              {
                fieldType: "textfield",
                columnWidth: 4,
                label: "Phone Number",
                model: "phone",
                clickMethod: "phoneClick"
              },
              {
                fieldType: "textarea",
                columnWidth: 4,
                label: "Comments",
                document_lines: 2,
                model: "comments",
                clickMethod: null
              },
              {
                fieldType: "select",
                model: "itemGroup",
                columnWidth: 4,
                label: "Uom",
                data: "uoms",
                itemText: "name",
                itemValue: "name"
              }
            ]
          },
          {
            id: 2,
            displayType: "tabs",
            tabs: [
              {
                id: 1,
                name: "Rows",
                contents: "one",
                displayType: "table"
              },
              {
                id: 2,
                name: "Attachments",
                contents: "two"
              },
              {
                id: 3,
                name: "Documents",
                contents: "three"
              }
            ]
          }
        ]
      }
    };
  },
  methods: {
    functionToChangeValue(data) {
      console.log(data, "d");
    },
    getData(data) {
      return this.$data[data];
    },
    save() {
      console.log(this.record, "record");
    },
    testClick(data) {
      const message = `${data} field event working`;
      alert(message);
    },
    phoneClick(data) {
      const message = `${data} field event working`;
      alert(message);
    },
    handleClick(methodName, name) {
      if(methodName !== null) {
        this[methodName](name);
      }
    },
     formSettings() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/marketingforms`)
        .then(res => {
          console.log(res, "data");
          self.loading = false;
        })
        .catch(err => {
          console.log(err, 'error');
        });
    }
  },
  created () {
    this.formSettings();
  },
};
</script>

<style>
.small {
  max-width: 600px;
  margin: 150px auto;
}
</style>