const CallIndex = () => import("./index");
const CreateCallPage = () => import("./_pages/create");
const ShowCallPage = () => import("./_pages/view");

const SubjectsAndActionsIndex = () =>
  import("./subjects-and-actions/index.vue");
const SubjectsAndActionsCreate = () =>
  import("./subjects-and-actions/_pages/create.vue");
const SubjectsAndActionsShow = () =>
  import("./subjects-and-actions/_pages/view.vue");

const ActivityStatusIndex = () => import("./activity-status/index.vue");
const ActivityStatusCreate = () =>
  import("./activity-status/_pages/create.vue");
const ActivityStatusShow = () => import("./activity-status/_pages/view.vue");
export default [
  {
    path: "/call-management",
    component: CallIndex,
  },
  {
    path: "/call-management/create",
    component: CreateCallPage,
  },
  {
    path: "/call-management/edit/:id",
    component: ShowCallPage,
  },
  {
    path: "/subjects_and_actions",
    component: SubjectsAndActionsIndex,
  },
  {
    path: "/subjects_and_actions/create",
    component: SubjectsAndActionsCreate,
  },
  {
    path: "/subjects_and_actions/edit/:id",
    component: SubjectsAndActionsShow,
  },

  {
    path: "/activity-status",
    component: ActivityStatusIndex,
  },
  {
    path: "/activity-status/create",
    component: ActivityStatusCreate,
  },
  {
    path: "/activity-status/edit/:id",
    component: ActivityStatusShow,
  },
];
