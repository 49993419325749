const LeaseAgreementIndex = () => import("./lease-agreement/index");
const NewLeaseAgreement = () => import("./lease-agreement/_pages/create");
const LeaseAgreementRecord = () => import("./lease-agreement/_pages/view");

// recurrent templates
const RecurrentTemplateIndex = () => import("./recurrent-templates/index");
const NewRecurrentTemplate = () =>
  import("./recurrent-templates/_pages/create");
const EditRecurrentTemplate = () =>
  import("./recurrent-templates/_pages/view.vue");
const RecurrentTransactionsIndex = () =>
  import("./recurrent-transactions/index.vue");

// sales doc general routes
const SalesDocIndex = () => import("./sale-docs/index");
const SalesDocDetails = () => import("./sale-docs/_pages/view");
const SalesDocCreate = () => import("./sale-docs/_pages/create");
const SalesDocCopy = () => import("./sale-docs/_pages/copy");

export default [
  // lease agreement
  {
    path: "/sales/lease-agreement",
    component: LeaseAgreementIndex,
  },
  {
    path: "/sales/new-lease-agreement",
    component: NewLeaseAgreement,
  },
  {
    path: "/lease-agreement-record/:id",
    component: LeaseAgreementRecord,
  },
  {
    path: "/sales/doc/:objectId",
    component: SalesDocIndex,
  },
  {
    path: "/sales/doc/create/:objectId",
    component: SalesDocCreate,
  },
  {
    path: "/sales/doc/copy/:baseObject/:docId/:copyToDoc",
    component: SalesDocCopy,
  },
  {
    path: "/sales/doc/:objectId/:id",
    component: SalesDocDetails,
  },

  {
    path: "/sales/recurrent-templates",
    component: RecurrentTemplateIndex,
  },
  {
    path: "/sales/new-recurrent-template",
    component: NewRecurrentTemplate,
  },
  {
    path: "/sales/view-recurrent-template/:id",
    component: EditRecurrentTemplate,
  },
  {
    path: "/sales/recurrent-transaction",
    component: RecurrentTransactionsIndex,
  },
];
