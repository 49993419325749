import Vue from "vue";
import Router from "vue-router";

import SystemDashboard from "@/components/dashboard/index.vue";
import MenuFormSettings from "@/components/form-settings/menu.vue";
import FormSettings from "@/components/form-settings/index.vue";
import FormTable from "@/components/form-settings/table.vue";
import FormFields from "@/components/form-settings/fields.vue";
// import AdminDashboard from '@/components/admin/dashboard/index.vue';
// import Pos from '@/components/pos/index.vue';
import LoginPage from "@/components/auth/login.vue";

// auth routes
import AuthRoutes from "@/components/auth/router";

// admin routes
import SubscribersRoutes from "@/components/admin/subscribers/router";
import PaymentsRoutes from "@/components/admin/payments/router";
import AllShopsRoutes from "@/components/admin/shops/router";

// - administration
import GlobalRoutes from "@/components/administration/global/router";
import SystemInitRoutes from "@/components/administration/system-init/router";
import SetupRoutes from "@/components/administration/setup/router";
import ApprovalRoutes from "@/components/administration/approval-process/router";
import AlertsManagement from "@/components/administration/alerts-management/router";
// end

// financials
import FinancialsRoutes from "@/components/financials/router";

// inventory main
import InventoryRoutes from "@/components/inventory/router";
// end

// business partners
import BpRoutes from "@/components/business-partners/router";
// end

// sales
import SalesRoutes from "@/components/sales/router";
import TradingSalesRoutes from "@/components/trading-sales/router";

// purchasing routes
import PurchasingRoutes from "@/components/purchasing/router";

// banking routes
import BankingRoutes from "@/components/banking/router";

// call management
import CallManagementRoutes from "@/components/logistics-hub/call-management/router";
// target
import TargetRoutes from "@/components/logistics-hub/targets/router";
// routes
import RoutePlanning from "@/components/logistics-hub/route-planning/router";
import channelsTiers from "@/components/logistics-hub/channels-tiers/router";
import SurveyRoutes from "@/components/logistics-hub/survey/router";
import DistributorsRoutes from "@/components/logistics-hub/distributors/router";
import MapsRoutes from "@/components/logistics-hub/maps/outlets/router";
import SalesForceRoutes from "@/components/logistics-hub/router";
import SalesReportsRoutes from "@/components/sales/reports/router";
import ReportsRoutes from "@/components/logistics-hub/reports/router";

import SalesDistributionRoutes from "@/components/sales-distribution/router";

import ServiceCallRoutes from "@/components/service/router";

//Gate Management Routes
import GatePassManagementRoutes from "@/components/gate-pass-management/router";

import DefaultLayout from "@/layout/defaultLayout";
import ForgotPassword from "@/components/auth/forgot-password.vue";
import PasswordReset from "@/components/auth/password-reset.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  // base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      name: "login",
      component: LoginPage,
    },
    {
      path: "/forgot/password",
      name: "PasswordForgotRoute",
      component: ForgotPassword,
    },
    {
      path: "/reset-password/:token/:email",
      name: "ResetPasswordRoute",
      component: PasswordReset,
    },
    // {
    //     path: '/',
    //     name: 'SystemIndex',
    //     component: Pos
    // },
    // {
    //     path: '/dashboard',
    //     name: 'SystemDashboard',
    //     component: SystemDashboard
    // },
    // {
    //     path: '/admin/dashboard',
    //     name: 'AdminDashboard',
    //     component: AdminDashboard
    // },
    // {
    //     path: '/menu/form/settings',
    //     name: 'MenuForm',
    //     component: MenuFormSettings
    // },
    {
      path: "/",
      component: DefaultLayout,
      beforeEnter: (to, from, next) => {
        const tokenStatus = localStorage.stoken;
        if (tokenStatus == undefined || tokenStatus == null) {
          next("/login");
        } else {
          next();
        }
      },
      children: [
        {
          path: "/form/table",
          name: "FormTable",
          component: FormTable,
        },
        {
          path: "/form/fields",
          name: "FormFields",
          component: FormFields,
        },
        {
          path: "/form/settings/:objectId",
          name: "FormSettings",
          component: FormSettings,
        },
        {
          path: "/dashboard",
          name: "SystemDashboard",
          component: SystemDashboard,
        },
        {
          path: "/menu/form/settings",
          name: "MenuForm",
          component: MenuFormSettings,
        },
        ...AuthRoutes,
        // system routes
        ...GlobalRoutes,
        ...SystemInitRoutes,
        ...SetupRoutes,
        ...ApprovalRoutes,
        // inventory routes
        ...InventoryRoutes,
        ...FinancialsRoutes,
        // BP Routes
        ...BpRoutes,
        // purchasing routes
        ...PurchasingRoutes,
        // sales
        ...SalesRoutes,
        ...SalesReportsRoutes,
        ...TradingSalesRoutes,
        // banking routes
        ...BankingRoutes,
        // salesforce routes
        ...SalesForceRoutes,
        ...CallManagementRoutes,
        ...TargetRoutes,
        ...RoutePlanning,
        ...channelsTiers,
        ...SurveyRoutes,
        ...MapsRoutes,
        ...DistributorsRoutes,
        ...SalesDistributionRoutes,
        ...GatePassManagementRoutes,
        ...ReportsRoutes,
        // admin routes
        ...SubscribersRoutes,
        ...PaymentsRoutes,
        ...AllShopsRoutes,
        ...ServiceCallRoutes,
        ...AlertsManagement,
      ],
    },
  ],
});
