const LeaseAgreementIndex = () => import('./lease-agreement/index')
const NewLeaseAgreement = () => import('./lease-agreement/_pages/create')
const LeaseAgreementRecord = () => import('./lease-agreement/_pages/view')

// offer letter
const OfferLetterIndex = () => import('./offer-letter/index')
const NewOfferLetter = () => import('./offer-letter/_pages/create')
const OfferLetterRecord = () => import('./offer-letter/_pages/view')

// a/r invoice
const ARInvoiceIndex = () => import('./ar-invoice/index')
const NewARInvoice = () => import('./ar-invoice/_pages/create')
const ARInvoiceRecord = () => import('./ar-invoice/_pages/view')
const ARInvoiceCopyRecord = () => import('./ar-invoice/_pages/copy')

// a/r invoice
const CreditMemoIndex = () => import('./credit-memo/index')
const NewCreditMemo = () => import('./credit-memo/_pages/create')
const ARCreditMemoRecord = () => import('./credit-memo/_pages/view')
const ARCreditMemoCopyRecord = () => import('./credit-memo/_pages/copy')

// downpayment invoice
const ARDownpaymentInvoiceIndex = () => import('./downpayment-invoice/index')
const NewARDownpaymentInvoice = () => import('./downpayment-invoice/_pages/create')

// delivery invoice
const DeliveryIndex = () => import('./delivery/index')
const NewDelivery = () => import('./delivery/_pages/create')
const DeliveryRecord = () => import('./delivery/_pages/view')
const DeliveryCopyRecord = () => import('./delivery/_pages/copy')

// recurrent templates
const RecurrentTemplateIndex = () => import('./recurrent-templates/index')
const NewRecurrentTemplate = () => import('./recurrent-templates/_pages/create')

export default [
    // lease agreement
    {
        path: '/trading/blanket-agreement',
        component: LeaseAgreementIndex
    },
    {
        path: '/trading/new-blanket-agreement',
        component: NewLeaseAgreement
    },
    {
        path: '/blanket-agreement-record/:id',
        component: LeaseAgreementRecord
    },
    // offer letter
    {
        path: '/trading/sales-order',
        component: OfferLetterIndex
    },
    {
        path: '/trading/new-sales-order',
        component: NewOfferLetter
    },
    {
        path: '/trading/sales-order/:id',
        component: OfferLetterRecord
    },
    // A/R Invoice
    {
        path: '/trading/ar-invoice',
        component: ARInvoiceIndex
    },
    {
        path: '/trading/new-ar-invoice',
        component: NewARInvoice
    },
    {
        path: '/trading/ar-invoice/:id',
        component: ARInvoiceRecord
    },
    {
        path: '/trading/ar-invoice/copy/:BaseType/:id',
        component: ARInvoiceCopyRecord
    },
     // A/R Downpayment Invoice
     {
        path: '/trading/ar-downpayment-invoice',
        component: ARDownpaymentInvoiceIndex
    },
    {
        path: '/trading/new-ar-downpayment-invoice',
        component: NewARDownpaymentInvoice
    },
    // Credit Memo
    {
        path: '/trading/ar-credit-memo',
        component: CreditMemoIndex
    },
    {
        path: '/trading/new-ar-credit-memo',
        component: NewCreditMemo
    },
    {
        path: '/trading/ar-credit-memo/:id',
        component: ARCreditMemoRecord
    },
    {
        path: '/trading/ar-credit-memo/copy/:BaseType/:id',
        component: ARCreditMemoCopyRecord
    },
    // Delivery
    {
        path: '/trading/delivery',
        component: DeliveryIndex
    },
    {
        path: '/trading/new-delivery',
        component: NewDelivery
    },
    {
        path: '/trading/delivery/:id',
        component: DeliveryRecord
    },
    {
        path: '/trading/delivery/copy/:BaseType/:id',
        component: DeliveryCopyRecord
    },
    // Recurrent Templates
    {
        path: '/trading/recurrent-templates',
        component: RecurrentTemplateIndex
    },
    {
        path: '/trading/new-recurrent-template',
        component: NewRecurrentTemplate
    },
]