const MapIndex = () => import("../outlets/map/index.vue");

const GpsLocationsShow = () => import("../gps-locations/_pages/show.vue");


const gpsSetupIndex = () => import("../gps-locations/index.vue");
const gpsSetupCreate = () => import("../gps-locations/_pages/create.vue");
const gpsSetupView = () => import("../gps-locations/_pages/view.vue");

export default [
  {
    path: "/outlets/map",
    component: MapIndex,
  },
  {
    path: "/gps-locations",
    component: GpsLocationsShow,
  },

  {
    path: "/gps-setup",
    component: gpsSetupIndex,
  },
  {
    path: "gps-setup/create",
    component: gpsSetupCreate,
  },
  {
    path: "gps-setup/:id",
    component: gpsSetupView,
  },
];
