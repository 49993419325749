// outlet routes
const OutletPage = () => import("./outlets/single/index");
const OutletSummary = () => import("./outlets/single/_pages/summary");
const OutletCalls = () => import("./outlets/single/_pages/calls");
const OutletSales = () => import("./outlets/single/_pages/sales");
const OutletContacts = () => import("./outlets/single/_pages/contacts");
// sales employees routes
const SalesEmployeePage = () => import("./sales-employee/index");
const SalesEmployeeCalls = () => import("./sales-employee/_pages/calls");
const SalesEmployeeOrders = () => import("./sales-employee/_pages/orders");
const SalesEmployeeRegions = () => import("./sales-employee/_pages/regions");

// region routes
const RegionPage = () => import("./region/index");
const RegionDetails = () => import("./region/_pages/details");
const RegionOutlets = () => import("./region/_pages/outlets");
const RegionSubs = () => import("./region/_pages/sub-regions");
const RegionSaleReps = () => import("./region/_pages/sale-reps");

//Employee Time-sheets
const EmpTimeSheetIndex = () => import("./employee-time-sheets/index.vue");
const EmpTimeSheetCreate = () =>
  import("./employee-time-sheets/_pages/create.vue");
const EmpTimeSheetView = () => import("./employee-time-sheets/_pages/view.vue");

//Reccuring Transactions
const ReccuringTransactions = () =>
  import("./recurrent-transactions/index.vue");
const ReccuringTransactionsTemplates = () =>
  import("./recurrent-templates/index.vue");
const ReccuringTransactionsTemplatesCreate = () =>
  import("./recurrent-templates/_pages/create.vue");
const ReccuringTransactionsTemplatesView = () =>
  import("./recurrent-templates/_pages/view.vue");
const SatSettingsView = () => import("./settings/_pages/general.vue");

export default [
  {
    path: "/sat_settings",
    component: SatSettingsView,
  },
  {
    path: "/outlet/:id",
    component: OutletPage,
    children: [
      {
        path: "",
        component: OutletSummary,
      },
      {
        path: "calls",
        component: OutletCalls,
      },
      {
        path: "sales",
        component: OutletSales,
      },
      {
        path: "contacts",
        component: OutletContacts,
      },
    ],
  },
  {
    path: "/region/:id",
    component: RegionPage,
    children: [
      {
        path: "",
        component: RegionDetails,
      },
      {
        path: "outlets",
        component: RegionOutlets,
      },
      {
        path: "sub-regions",
        component: RegionSubs,
      },
      {
        path: "sale-reps",
        component: RegionSaleReps,
      },
    ],
  },
  {
    path: "/sales/employee/:id",
    component: SalesEmployeePage,
    children: [
      {
        path: "",
        component: SalesEmployeeCalls,
      },
      {
        path: "orders",
        component: SalesEmployeeOrders,
      },
      {
        path: "regions",
        component: SalesEmployeeRegions,
      },
    ],
  },
  {
    path: "/employee-timesheet",
    component: EmpTimeSheetIndex,
  },
  {
    path: "employee-timesheet/create",
    component: EmpTimeSheetCreate,
  },
  {
    path: "employee-timesheet/:id",
    component: EmpTimeSheetView,
  },
  {
    path: "logistics_hub/reccuring_transactions",
    component: ReccuringTransactions,
  },
  {
    path: "logistics_hub/reccuring_templates",
    component: ReccuringTransactionsTemplates,
  },
  {
    path: "logistics_hub/reccuring_templates/create",
    component: ReccuringTransactionsTemplatesCreate,
  },
  {
    path: "logistics_hub/reccuring_templates/:id",
    component: ReccuringTransactionsTemplatesView,
  },
];
