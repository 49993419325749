const Index = () => import('./index');
const CreatePage = () => import('./_pages/create');

export default [
    {
        path: '/distributors',
        component: Index
    },
    {
        path: '/new-distributor',
        component: CreatePage
    }
];