import axios from "axios";
// Add a request interceptor

/**
 * Live Environment
 */
//export const rootUrl = "https://api.leysco100.com";

/**
 * Test Environment
 */

export const rootUrl = localStorage.getItem("apiBaseUrl");

//check if local settings are enabled and change the api base url

//export const rootUrl = "http://127.0.0.1:8000/api/v1";
export const host = `${rootUrl}`;
//  export const host = ''
export const base = `${rootUrl}`;
//
export const http = axios.create({
  baseURL: host,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
