<template>
  <v-app id="inspire">
    <router-view></router-view>

    <loader></loader>

    <!-- <v-sheet
      :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
      class="pa-3"
    >
      <v-skeleton-loader
        class="mx-auto"
        max-width="300"
        type="card"
      ></v-skeleton-loader>
    </v-sheet> -->
  </v-app>
</template>

<script>
// import { http } from "@/common/index";
import Loader from "./components/global/loader.vue";
export default {
  name: "App",
  components: { Loader },
  data: () => ({
    //
  }),
  methods: {},
  created() {},
};
</script>
