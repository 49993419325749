const ServiceCallIndex = () => import("./service-call/index.vue");

const ServiceCallCreate = () => import("./service-call/_pages/create.vue");

const ServiceCallView = () => import("./service-call/_pages/view.vue");

const EquipmentCardIndex = () => import("./equipment-card/index.vue");

const EquipmentCardCreate = () => import("./equipment-card/_pages/create.vue");
const EquipmentCardView = () => import("./equipment-card/_pages/view.vue");

export default [
  {
    path: "/service-call",
    component: ServiceCallIndex,
  },
  {
    path: "/service-call/create",
    component: ServiceCallCreate,
  },
  {
    path: "/service-call/edit/:id",
    component: ServiceCallView,
  },

  {
    path: "/equipment-card",
    component: EquipmentCardIndex,
  },

  {
    path: "/equipment-card/create",
    component: EquipmentCardCreate,
  },
  {
    path: "/equipment-card/edit/:id",
    component: EquipmentCardView,
  },
];
