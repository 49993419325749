const ChartIndex = () => import("./chart-of-accounts/index.vue");
const TestIndex = () => import("./chart-of-accounts/test.vue");

// journal entry
const JournalIndex = () => import("./journal-entry/index.vue");
const NewJournalEntry = () => import("./journal-entry/_pages/create.vue");

//Cost Accounting

const DimensionIndex = () => import("./cost-accounting/dimensions/index.vue");
const ViewDimension = () =>
  import("./cost-accounting/dimensions/_pages/view.vue");

const CostCentersIndex = () =>
  import("./cost-accounting/cost-centers/index.vue");
const CostCenterCreate = () =>
  import("./cost-accounting/cost-centers/_pages/create.vue");

//Distribution Rules

const DistributionRulesIndex = () =>
  import("./cost-accounting/distributions-rules/index.vue");
const DistributionRulesCreate = () =>
  import("./cost-accounting/distributions-rules/_pages/create.vue");
const DistributionRulesView = () =>
  import("./cost-accounting/distributions-rules/_pages/view.vue");

//Branches
const BranchIndex = () => import("./cost-accounting/branches/index.vue");
const BranchCreate = () =>
  import("./cost-accounting/branches/_pages/create.vue");
const BranchView = () => import("./cost-accounting/branches/_pages/view.vue");

//Locations
const LocationIndex = () => import("./cost-accounting/locations/index.vue");
const LocationCreate = () =>
  import("./cost-accounting/locations/_pages/create.vue");
const LocationView = () =>
  import("./cost-accounting/locations/_pages/view.vue");

export default [
  {
    path: "/chart-of-accounts",
    component: ChartIndex,
  },
  {
    path: "/test-chart-of-accounts",
    component: TestIndex,
  },
  {
    path: "/journal-entry",
    component: JournalIndex,
  },
  {
    path: "/new-journal-entry",
    component: NewJournalEntry,
  },
  //Cost Accounting
  {
    path: "/cost-accounting/dimensions",
    component: DimensionIndex,
  },
  {
    path: "/cost-accounting/dimensions/:id",
    component: ViewDimension,
  },

  // Gettings
  {
    path: "/cost-accounting/cost-centres",
    component: CostCentersIndex,
  },
  {
    path: "/cost-accounting/cost-centres/create",
    component: CostCenterCreate,
  },
  {
    path: "/cost-accounting/cost-centers/:id",
    component: ViewDimension,
  },

  // Gettings
  {
    path: "/cost-accounting/distribution-rules",
    component: DistributionRulesIndex,
  },
  {
    path: "/cost-accounting/distribution-rules/:id",
    component: DistributionRulesView,
  },
  {
    path: "/cost-accounting/distribution-rules/create",
    component: DistributionRulesCreate,
  },

  // Gettings
  {
    path: "/branches",
    component: BranchIndex,
  },
  {
    path: "/branches/create",
    component: BranchCreate,
  },
  {
    path: "/branches/:id",
    component: BranchView,
  },

  // Gettings
  {
    path: "/locations",
    component: LocationIndex,
  },
  {
    path: "/locations/create",
    component: LocationCreate,
  },
  {
    path: "/locations/:id",
    component: LocationView,
  },
];
