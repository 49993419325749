//outgoing payments
const OutgoingPaymentIndex = () => import("./outgoing-payments/index");
const NewOutgoingPayment = () => import("./outgoing-payments/_pages/create");
const ViewOutgoingPayment = () => import("./outgoing-payments/_pages/view");
//Incoming Payments
const IncomingPaymentIndex = () => import("./incoming-payments/index");
const NewIncomingPayment = () => import("./incoming-payments/_pages/create");
const ViewIncomingPayment = () => import("./incoming-payments/_pages/view");

// deposits
const DepositsIndex = () => import("./deposits/deposit/index");
const NewDeposit = () => import("./deposits/deposit/_pages/create");
// postdated checks
const PostdatedCheckIndex = () => import("./deposits/postdated-checks/index");
const NewPostdatedCheck = () =>
  import("./deposits/postdated-checks/_pages/create");
// credit voucher
const PostdatedVoucherIndex = () =>
  import("./deposits/postdated-vouchers/index");
const NewPostdatedCreditVoucher = () =>
  import("./deposits/postdated-vouchers/_pages/create");

//payments
const ThirdPartyPaymentsIndex = () =>
  import("./incoming-payments/third-party-payments/index.vue");
const ThirdPartyPaymentsModes = () =>
  import("./incoming-payments/paymentModes/index.vue");

export default [
  //outgoingpayments
  {
    path: "/banking/outgoing-payments/on-account",
    component: OutgoingPaymentIndex,
  },
  {
    path: "/banking/new-outgoing-payment",
    component: NewOutgoingPayment,
  },

  {
    path: "/banking/outgoing-payment/:id",
    component: ViewOutgoingPayment,
  },

  //third-party
  {
    path: "/payments/incoming/third-party",
    component: ThirdPartyPaymentsIndex,
  },
  {
    path: "/payments/incoming/third-party/modes",
    component: ThirdPartyPaymentsModes,
  },
  //incoming payments
  {
    path: "/banking/incoming-payments",
    component: IncomingPaymentIndex,
  },
  {
    path: "/banking/new-incoming-payments",
    component: NewIncomingPayment,
  },
  {
    path: "/banking/incoming-payments/:id",
    component: ViewIncomingPayment,
  },

  // deposits
  {
    path: "/deposits",
    component: DepositsIndex,
  },
  {
    path: "/new-deposit",
    component: NewDeposit,
  },
  {
    path: "/postdated-checks",
    component: PostdatedCheckIndex,
  },
  {
    path: "/new-postdated-check",
    component: NewPostdatedCheck,
  },
  {
    path: "/postdated-credit-voucher",
    component: PostdatedVoucherIndex,
  },
  {
    path: "/new-postdated-credit-voucher",
    component: NewPostdatedCreditVoucher,
  },
];
