<template>
<div>
    {{model}}
  <v-radio-group :v-model="model" :value="invUom" row>
    <template v-slot:label>
        <div><strong>Label come hehe</strong></div>
      </template>
    <v-radio
      v-for="(option, index) in setup.options"
      v-bind:key="index"
      :label="`${option.label}`"
      :value="option.value"
    ></v-radio>
  </v-radio-group>
</div>

</template>

<script>
export default {
  props: {
    setup: {
      type: Object,
      required: true
    },
    model: {
        type: String
    }
  },
  data() {
    return {
    invUom: 'Y'
    }
  },
  methods: {
    getData(data) {
      return this.$data[data];
    }
  }
};
</script>

<style  scoped>
</style>