const Index = () => import("./index");
const RouteDetails = () => import("./_pages/route-details");
const CreateRoute = () => import("./_pages/create");
const ShowRoute = () => import("./_pages/view");

const CreateCallPage = () => import("./_pages/create-call");
const CreateOutletPage = () => import("./_pages/create-outlet");
const RouteOutlets = () => import("./_pages/outlets");
const RouteVisits = () => import("./_pages/visits");

const RouteAssignments = () => import("./assignments/index");
const RouteAssignmentCreate = () => import("./assignments/pages/create");
const RouteAssignmentView = () => import("./assignments/pages/view");

const RouteAssignmentIndex = () => import("./route-assignments/index.vue");
const RouteAssignmentsCreate = () =>
  import("./route-assignments/_pages/create.vue");
const RouteAssignmentEdit = () => import("./route-assignments/_pages/view.vue");

export default [
  {
    path: "/routes",
    component: Index,
  },
  {
    path: "/routes/:id",
    component: RouteDetails,
    children: [
      {
        path: "",
        component: RouteOutlets,
      },
      {
        path: "calls",
        component: RouteVisits,
      },
    ],
  },

  {
    path: "routes-outlets/:id",
    component: RouteOutlets,
  },
  {
    path: "/routes_planning",
    component: CreateRoute,
  },
  {
    path: "/routes_planning/:id",
    component: ShowRoute,
  },
  {
    path: "/route-outlets",
    component: RouteOutlets,
  },
  {
    path: "/route-visits",
    component: RouteVisits,
  },
  {
    path: "/route/visit/create/:id",
    component: CreateCallPage,
  },
  {
    path: "/route/outlet/create/:id",
    component: CreateOutletPage,
  },
  {
    path: "/routes-assignment/:id",
    component: RouteAssignments,
  },

  {
    path: "/routes-assignments",
    component: RouteAssignmentIndex,
  },
  {
    path: "/routes-assignments/create",
    component: RouteAssignmentsCreate,
  },
  {
    path: "/routes-assignments/:id",
    component: RouteAssignmentEdit,
  },

  {
    path: "/route/:id/user-assignment",
    component: RouteAssignmentCreate,
  },

  {
    path: "/route/assignment-edit/:id",
    component: RouteAssignmentView,
  },
];
