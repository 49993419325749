// refreshMixin.js
export default {
    data() {
        return {
            isRefreshing: false,
            refreshMessage: 'This page is about to refresh. Your changes may be lost.',
        };
    },
    methods: {
        handlePageRefresh() {
            this.isRefreshing = true;
            // Perform any cleanup or other actions before the refresh if needed.
            //1. get logged-in user data

            //2. if local search enabled make local api calls to allow login to sap

            console.log("refreshing page")
            // For example, saving state to local storage.
        },
    },
    created() {
        window.addEventListener('beforeunload', this.handlePageRefresh);
    },
    beforeDestroy() {
        window.removeEventListener('beforeunload', this.handlePageRefresh);
    },
};
