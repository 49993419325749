<template>
  <v-textarea
    outlined
    :v-model="setup.model"
    :label="setup.label"
    :document_lines="setup.document_lines"
  ></v-textarea>
</template>

<script>
export default {
    props: {
        setup: {
            type: Object,
            required: true
        }
    }
};
</script>

<style scoped>
</style>