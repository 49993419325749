// company details
const CompanyDetailsIndex = () => import("./company-details/index.vue");
const AccountingIndex = () => import("./company-details/_pages/accounting.vue");
const GeneralIndex = () => import("./company-details/_pages/general.vue");
const InstallationIndex = () =>
  import("./company-details/_pages/installation.vue");
// const CreatePage = () => import('./_pages/create.vue');

// general settings
const GeneralSettingsIndex = () => import("./general-settings/index.vue");
// doc settings
const DocSettingsIndex = () => import("./doc-settings/index.vue");
// general authorization
const GeneralAuthorizationIndex = () =>
  import("./general-authorization/index.vue");
//data ownerships
const DataOwnershipIndex = () => import("./data-ownerships/index.vue");
// document numbering
const DocumentNumberingIndex = () => import("./document-numbering/index.vue");
const DocumentNumberingSetup = () =>
  import("./document-numbering/_pages/setup.vue");
//Document Approvals
const DocumentApprovalIndex = () => import("./document-approval/index.vue");

const IndexPage = () => import("./doc-settings/index.vue");

const GenSettings = () => import("./doc-settings/pages/general-settings.vue");
const OtherSettings = () => import("./doc-settings/pages/othersettings.vue");
export default [
  {
    path: "/company-details",
    component: CompanyDetailsIndex,
    children: [
      {
        path: "",
        component: GeneralIndex,
      },
      {
        path: "accounting",
        component: AccountingIndex,
      },
      {
        path: "initialization",
        component: InstallationIndex,
      },
    ],
  },
  // {
  //     path: '/new-customer',
  //     name: 'NewCustomerPage',
  //     component: CreatePage
  // },
  // },
  {
    path: "/system-initialization/general-settings",
    component: GeneralSettingsIndex,
  },
  {
    path: "/system-initialization/doc-settings",
    component: DocSettingsIndex,
  },
  {
    path: "/general-authorization",
    component: GeneralAuthorizationIndex,
  },
  {
    path: "/data-ownerships",
    component: DataOwnershipIndex,
  },
  {
    path: "/document-numbering",
    component: DocumentNumberingIndex,
  },
  {
    path: "/document-numbering/:id",
    component: DocumentNumberingSetup,
  },
  {
    path: "/document-approvals",
    component: DocumentApprovalIndex,
  },
  {
    path: "/doc-settings",
    component: IndexPage,
    children: [
      {
        path: "",
        component: GenSettings,
      },
      {
        path: "/doc-settings/others",
        component: OtherSettings,
      },
    ],
  },
];
