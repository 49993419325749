const AlertsIndex = () => import("./index.vue");
const AlertsCreate = () => import("./_pages/create.vue");
const AlertsEdit = () => import("./_pages/edit.vue");

const AlertVariableIndex = () => import("./variables/index.vue");
const AlertVariableCreate = () => import("./variables/_pages/create.vue");
const AlertVariableEdit = () => import("./variables/_pages/edit.vue");

export default [
  {
    path: "/alerts",
    component: AlertsIndex,
  },
  {
    path: "/alerts/create",
    component: AlertsCreate,
  },
  {
    path: "/alerts/:id",
    component: AlertsEdit,
  },

  {
    path: "/alerts_variables",
    component: AlertVariableIndex,
  },
  {
    path: "/alerts_variables/create",
    component: AlertVariableCreate,
  },
  {
    path: "/alert_variables/:id",
    component: AlertVariableEdit,
  },
];
