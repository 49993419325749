<template>
  <v-app id="inspire">
    <v-content>
        <slot></slot>
    </v-content>
  </v-app>
</template>

<script>
export default {
  name: 'AuthLayout'
}
</script>