import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  buttons: {
    capitalize: false,
  },
  theme: {
    themes: {
      light: {
        primary: '#0277BD',
        accent: '#42A5F5',
        secondary: '#EDE7F6',
        error: '#b71c1c',
        tabToolbar: '#EFEBE9',
        modalToolbar: '#0277BD'
      },
    },
  }
});
