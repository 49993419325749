const IndexPage = () => import("./index");
const OpenOrders = () => import("./_pages/open");
const DispatchedOrders = () => import("./_pages/dispatched");
const DeliveredOrders = () => import("./_pages/delivery");
const Assignments = () => import("./_pages/assignments");
const DeliveryReturns = () => import("./_pages/returns");
const DispatchDocument = () => import("./_pages/view.vue");
const DispatchDashboard = () => import("./_pages/dashboard.vue");

export default [
  {
    path: "/dispatch/document/:id/:ObjType",
    component: DispatchDocument,
  },
  {
    path: "/dispatch",
    component: IndexPage,
    children: [
      {
        path: "",
        component: OpenOrders,
      },
      {
        path: "/dispatch/assignments",
        component: Assignments,
      },
      {
        path: "/dispatch/dipatch_notes",
        component: DispatchedOrders,
      },

      {
        path: "/dispatch/delivered",
        component: DeliveredOrders,
      },
      {
        path: "/dispatch/returns",
        component: DeliveryReturns,
      },
    ],
  },
  {
    path: "/dispatch/dashboard",
    component: DispatchDashboard,
  },
];
