// approval stages
const StagesIndex = () => import('./approval-stages/index.vue')
const CreateStage = () => import('./approval-stages/_pages/create.vue')
const ViewStage = () => import('./approval-stages/_pages/view.vue')
// approval templates
const TemplatesIndex = () => import('./approval-templates/index.vue')
const CreateTemplate = () => import('./approval-templates/_pages/create.vue')
const ViewTemplate = () => import('./approval-templates/_pages/view.vue')
// approval status report
const StatusReportIndex = () => import('./status-report/index.vue')
const DecisionReportIndex = () => import('./decision-report/index.vue')
const ApprovedStatus = () => import('./status-report/_pages/approved.vue')
const PendingStatus = () => import('./status-report/_pages/pending.vue')
const RejectedStatus = () => import('./status-report/_pages/rejected.vue')
const GeneratedStatus = () => import("./status-report/_pages/generated.vue");
const AuthorizerStatus = () => import("./status-report/_pages/authorizer.vue");
const CanceledStatus = () => import("./status-report/_pages/canceled.vue");
//decision report
const ApprovedDocuments = () => import("./decision-report/_pages/approved.vue");
const PendingDocuments = () => import("./decision-report/_pages/pending.vue");
const RejectedDocuments = () => import("./decision-report/_pages/rejected.vue");


export default [
    {
        path: '/approval-stages',
        component: StagesIndex
    },
    {
        path: '/approval-stages/create',
        component: CreateStage
    },
    {
        path: '/approval-stages/details/:id',
        component: ViewStage
    },
    {
        path: '/approval-templates',
        component: TemplatesIndex
    },
    {
        path: '/approval-templates/create',
        component: CreateTemplate
    },
    {
        path: '/approval-templates/details/:id',
        component: ViewTemplate
    },
    {
        path: '/approval-status-report',
        component: StatusReportIndex,
        children: [
            {
                path: "",
                component: PendingStatus,
            },
            {
                path: "/approval_status/pending",
                component: PendingStatus
            },
            {
                path: "/approval_status/approved",
                component: ApprovedStatus
            },
            {
                path: "/approval_status/rejected",
                component: RejectedStatus
            },
            {
                path: "/approval_status/generated",
                component: GeneratedStatus
            },
            {
                path: "/approval_status/authorizer",
                component: AuthorizerStatus
            },
            {
                path: "/approval_status/canceled",
                component: CanceledStatus
            }
        ]
    },
    {
        path: '/approval-decision-report',
        component: DecisionReportIndex,
        children: [
            {
                path: "",
                component: ApprovedDocuments,
            },
            {
                path: "/approvals/approved",
                component: ApprovedDocuments
            },
            {
                path: "/approvals/pending",
                component: PendingDocuments
            },
            {
                path: "/approvals/rejected",
                component: RejectedDocuments
            }
        ]
    },
    
];