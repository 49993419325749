// choose company
const ChooseCompanyIndex = () => import('./choose-company/index.vue');
// rates
const RatesIndex = () => import('./rates-and-indexes/index.vue');

export default [
    {
        path: '/rates-and-indexes',
        component: RatesIndex
    },
    {
        path: '/choose-company',
        component: ChooseCompanyIndex
    },
]