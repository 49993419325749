<template>
  <v-container fluid>

    <v-row dense>
      <v-tabs
        background-color="accent"
        color="white"
      >
        <v-tab>Change Password</v-tab>
        <v-tab v-show="this.CurrentlyLoginUser.id == 1">Menu Form Settings</v-tab>

        <v-tab-item>
          <v-form>

            <v-row>
              <v-col cols="6">
                <v-text-field
                  outlined
                  dense
                  type="password"
                  autocomplete="nope"
                  required
                  v-model="record.old_password"
                  label="Current Password"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  outlined
                  dense
                  type="password"
                  required
                  autocomplete="nope"
                  v-model="record.new_password"
                  label="New Password"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  outlined
                  dense
                  type="password"
                  required
                  autocomplete="nope"
                  v-model="record.new_confirm_password"
                  label="Confirm New Password"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-col cols="12">
              <v-btn
                color="accent"
                @click="updatePassword"
                :loading="$store.state.loader"
              >
                <v-icon left>mdi-content-save</v-icon>Submit
              </v-btn>
            </v-col>
          </v-form>
        </v-tab-item>

        <v-tab-item>
          <v-row>
            <v-toolbar
              class="secondary"
              dense
              flat
            >
              <v-btn
                outlined
                @click="save"
                :loading="loader"
              >Update</v-btn>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col cols="3">
                  <v-autocomplete
                    outlined
                    dense
                    v-model="UserSign"
                    :items="users"
                    item-text="name"
                    item-value="id"
                    label="User"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-treeview
                    v-model="menuItems"
                    selectable
                    selection-type="leaf"
                    color="accent"
                    :activatable="trueValue"
                    :hoverable="trueValue"
                    item-text="Label"
                    item-children="grand_children"
                    :items="items"
                    :search="search"
                    :open-all="trueValue"
                  >
                    <template v-slot:label="{ item }">
                      <div @click="selectItem(item)">
                        <span style="font-weight: bold">{{item.Label}}</span>
                      </div>
                    </template>
                  </v-treeview>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-dialog
                    v-model="dialog"
                    max-width="650"
                  >
                    <v-card>
                      <v-toolbar
                        color="accent"
                        dense
                        dark
                      >
                        <v-toolbar-title>Menu Item Update</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                          icon
                          @click.native="dialog = false"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-toolbar>
                      <v-card-text>
                        <v-row>
                          <v-col cols="6">
                            <v-text-field
                              outlined
                              dense
                              v-model="activeItem.Label"
                              label="Label"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12">
                            <v-btn
                              color="accent"
                              @click="updateMenu"
                            >Update</v-btn>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-card-text>
          </v-row>
        </v-tab-item>

      </v-tabs>
    </v-row>
    <snackbar ref="snackbar"></snackbar>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      dialog: false,
      loader: false,
      loading: false,
      activeItem: {},
      allItems: [],
      menuItems: [],
      users: [],
      UserSign: null,
      items: [],
      trueValue: true,
      search: null,
      record: {},
    };
  },
  computed:{
    ...mapGetters(
        {
          getFormSettingsMenu: "defaultFormSettingMenu",
        }
    ),
    CurrentlyLoginUser() {
      return this.$store.state.user;
    }
  },
  watch: {
    UserSign: {
      handler: "getMenu",
      immediate: true,
    },
  },
  methods: {
    selectItem(data) {
      this.activeItem = data;
      this.dialog = true;
    },
    updateMenu() {
      const menuItem = this.activeItem;
      const data = { Label: menuItem.Label };
      const url = `/form_settings_menu/${menuItem.id}`;
      const self = this;
      self.loader = true;
      this.$store
        .dispatch("post", { url, data })
        .then(() => {
          self.loader = false;
        })
        .finally(() => {
          location.reload();
        })
        .catch((err) => {
          console.log(err, "err");
          // this.$refs.snackbar.show(err, "red");
        });
    },
    getMenu(val) {
      const self = this;
      if (val){
        this.$store
            .dispatch("get", `/form_settings_menu/user/${val}`)
            .then((res) => {
              self.items = res.AllTreeview;
              self.allItems = res.AllIds;
              self.menuItems = res.VisibleIds;
              // self.$store.commit("setDefaultFormSettingMenu",res.VisibleIds);
            })
            .catch((err) => {
              self.$refs.snackbar.show(err, "red");
            });
      }

    },
    getUsers() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/users?t='NU'`)
        .then((res) => {
          self.users = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    save() {
      const self = this;
      self.loader = true;
      const allIds = this.allItems;
      const selected = this.menuItems;
      const UserSign = this.UserSign;
      var b = new Set(selected);
      var diff = [...allIds].filter((x) => !b.has(x));
      // define data
      const data = { AllIds: diff, SelectedIds: selected, UserSign: UserSign };
      console.log(data, "data");
      const url = "/form_settings_menu";
      this.$store
        .dispatch("post", { url, data })
        .then(() => {
          self.loader = false;
          // location.reload();
        })
        .catch((err) => {
          self.$refs.snackbar.show(err, "red");
          self.loader = false;
        });
    },
    updatePassword() {
      const self = this;
      const url = "/update-user-password";
      const data = this.record;
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            this.record = {};
            self.$refs.snackbar.show("Password updated successfully", "green");
          } else {
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
          this.$store.commit("loader", false);
        })
        .catch((err) => {
          console.log(err, "err");
          this.$store.commit("loader", false);
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    // this.getMenu();
    this.getUsers();
  },
};
</script>

<style lang="scss" scoped>
</style>