<template>
  <default-layout>
    <router-view></router-view>
  </default-layout>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>