<template>
  <v-autocomplete
    :v-model="setup.model"
    :items="selectData"
    :item-text="setup.itemText"
    :item-value="setup.itemValue"
    :label="setup.label"
  ></v-autocomplete>
</template>

<script>
export default {
    props: {
        selectData: {
            type: Array
        },
        setup: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
           
        }
    },
};
</script>

<style lang="scss" scoped>
</style>